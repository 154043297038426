import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const CreateItemParameterAssociation = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();

  // select dropdown
  const [item, setItem] = useState([]);
  const [parameter, setParameter] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useCreate } = useAPIQuery({
    key: "item-parameter",
    endpoint: "/api/item-parameter-association/",
  });

  const handleCreateItemParameterAssoc = (data) => {
    Swal.fire({
      title: "Item Parameter Association Created!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorItemParameterAssoc = (data) => {
    Swal.fire({
      title: "Error Creating Item Parameter Association",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateItemParameterAssoc,
    onError: handleErrorItemParameterAssoc,
    retry : 2
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var item_parameter = {
      item_id: data.item_id.value,
      parameter_id: data.parameter_id.value,
      created_by : userID,
      updated_by : userID
    };

    mutate({
      data: item_parameter,
    });
  };

  useEffect(() => {
    if (data) {
      setItem(data.item);
      setParameter(data.quality_parameter);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Item Parameter Association </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Parameter <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={parameter?.map((api) => {
                          return {
                            value: api.id,
                            label: api?.parameter_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-parameter"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="parameter_id"
                    control={control}
                  />
                  {errors.parameter_id && (
                    <span className="fs-8 text-danger">
                      Parameter Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((api) => {
                          return {
                            value: api.id,
                            label: api?.item_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-item-parameter-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-item-parameter"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateItemParameterAssociation;
