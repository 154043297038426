import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "item_code",
    header: "Item Code",
    cell: (row) => <>{row?.item?.item_code ? row?.item?.item_code : "-"}</>,
  },
  {
    accessor: "item_name",
    header: "Item Name",
    cell: (row) => <>{row?.item?.item_name ? row?.item?.item_name : "-"}</>,
  },
  {
    accessor: "client",
    header: "Client Name",
    cell: (row) => <>{row?.client?.user?.first_name + " " + row?.client?.user?.last_name}</>
  },
];

const ClientItemAssociation = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [associationEntries, setAssociationEntries] = useState(5);
  const [associationPageIndex, setAssociationPageIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { useFetch } = useAPIQuery({
    key: "client-item",
    endpoint: "/api/client-item-association/",
  });

  const { data, isLoading } = useFetch({
    params: {
      entries: associationEntries,
      page_no: associationPageIndex + 1,
      event: search ? search : undefined,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime : 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true
  });

  const { useDelete } = useAPIQuery({
    key: "client-item",
    endpoint: "/api/client-item-association/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setAssociationPageIndex(value.pageIndex);
    setAssociationEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-client-item", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Client - Item Association</h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("client_item_create") && (
            <div className="nk-block-head-content">
              <NavLink
                to="/create-client-item"
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-create-client-item"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to="/create-client-item"
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-create-client-item"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Client-Item Association</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <Table
        data={data ? data?.results.data : []}
        columns={columns}
        pagination
        pageIndex={associationPageIndex}
        pageSize={associationEntries}
        rowCount={data ? data.count : 0}
        globalSearch
        editRows={privileges.includes("client_item_update") ? true : false}
        deleteRows={privileges.includes("client_item_delete") ? true : false}
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default ClientItemAssociation;
