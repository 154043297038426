import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingCalendar = () => {
    return (
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <Skeleton
                height="40px"
                style={{ marginBottom: "10px", width: "200px" }}
            />
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(7, 1fr)",
                    gap: "10px",
                }}
            >
                {Array.from({ length: 35 }).map((_, i) => (
                    <Skeleton key={i} height="100px" />
                ))}
            </div>
        </div>
    );
};

export default LoadingCalendar;