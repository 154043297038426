import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { APIQueryProvider } from "@dev2_techwalnut/tw-api-query-hook";
import GlobalContextProvider from "./Component/Context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <React.StrictMode>
            <BrowserRouter>
                <APIQueryProvider
                    baseURL={process.env.REACT_APP_API_URL}
                    accessTokenKey="accessToken"
                    refreshTokenKey="refreshToken"
                >
                    <GlobalContextProvider>
                        <App />
                    </GlobalContextProvider>
                </APIQueryProvider>
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
