import React, { useEffect, useState } from "react";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import sample_parameter from "./sample-parameter.json";
import parameter from "./parameter.json";
import { useForm } from "react-hook-form";

const QC = () => {
  const detailsData = sample_parameter.results.data;
  const parametersData = parameter.results.data;
  const [tableNewData, setTableNewData] = useState([]);
  const selectedOptions = [2, 3];
  console.log(tableNewData);
  console.log(detailsData);
  console.log(parametersData);

  const filteredData = parametersData.filter((item) =>
    selectedOptions?.includes(item.id)
  );
  console.log(filteredData);

  const onSubmit = (data) => {
    console.log(data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const columns = [
    {
      accessor: "item_id",
      header: "Parameters/Samples",
      cell: (row) => <span>{row?.sample}</span>,
    },
    ...filteredData.map((param) => ({
      accessor: param.parameter_name,
      header: param.parameter_name,
      cell: (row) => {
        const fieldName = `item-${param.parameter_name}-${
          row[param.parameter_name]?.id
        }`;
        console.log(row);
        return (
          <div style={{ display: "flex" }}>
            <input
              type="text"
              value={row[param.parameter_name]?.value}
              className="form-control"
              id={`item-${param.parameter_name}-${
                row[param.parameter_name]?.id
              }`}
              {...register(`item-${row[param.parameter_name]?.id}`)}
              // onChange={(e) => handleChange(e.target.value)}
            />
            {row[param.parameter_name]?.parameter?.type_of_input === 1 && (
              <button
                className="btn btn-sm btn-primary ms-2"
                type="button"
                onClick={() => {
                  // Get the input element by its ID
                  const inputElement = document.getElementById(
                    `item-${param.parameter_name}-${
                      row[param.parameter_name]?.id
                    }`
                  );
                  setValue(
                    `item-${param.parameter_name}-${
                      row[param.parameter_name]?.id
                    }`,
                    (inputElement.value =
                      document.getElementById("mqtt-value").value) || ""
                  );
                  setTimeout(() => {
                    handleSubmit(onSubmit)();
                  }, 500);
                }}
                // onClick={() => handleButtonClick(row, param)}
              >
                get
              </button>
            )}
          </div>
        );
      },
    })),
  ];

  useEffect(() => {
    if (detailsData) {
      console.log(detailsData);
      console.log(
        detailsData?.map((data) => ({
          item_id: data?.sample,
          ...data,
        }))
      );
      setTableNewData(
        detailsData?.map((data) => ({
          item_id: data?.sample,
          ...data,
        }))
      );
    }
  }, [detailsData]);

  return (
    <>
      <Table
        data={tableNewData}
        columns={columns}
        selectRows={false}
        globalSearch={false}
        filterModal={false}
        editRows={false}
        deleteRows={false}
        pagination={false}
        minWidth={800}
      />
    </>
  );
};

export default QC;
