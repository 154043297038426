import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditSamplingPlan = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // select dropdown
  const [qualityCheck, setQualityCheck] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "sampling_plan",
    endpoint: "/api/sampling-plan/",
  });

  const handleUpdateSamplingPlan = (data) => {
    Swal.fire({
      title: "Sampling Plan Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorSamplingPlan = (data) => {
    Swal.fire({
      title: "Error Updating Sampling Plan",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateSamplingPlan,
    onError: handleErrorSamplingPlan,
    retry: 2,
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var sampling_plan = {
      minimum_lot_size: data.minimum_lot_size,
      maximum_lot_size: data.maximum_lot_size,
      sample_size: data.sample_size,
      code_letter: data.code_letter,
      critical_threshold: data.critical_threshold,
      major_threshold: data.major_threshold,
      minor_threshold: data.minor_threshold,
      type_name_id: data.type_name_id.value,
      updated_by: userID,
    };

    mutate({
      id: location.state.editpost.id,
      data: sampling_plan,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editSamplingPlan = location.state.editpost;
    // defaultValues is a react form hook.
    if (editSamplingPlan) {
      var defaultValues = {};
      defaultValues.critical_threshold = editSamplingPlan.critical_threshold;
      defaultValues.major_threshold = editSamplingPlan.major_threshold;
      defaultValues.minor_threshold = editSamplingPlan.minor_threshold;
      defaultValues.minimum_lot_size = editSamplingPlan.minimum_lot_size;
      defaultValues.maximum_lot_size = editSamplingPlan.maximum_lot_size;
      defaultValues.sample_size = editSamplingPlan.sample_size;
      defaultValues.code_letter = editSamplingPlan.code_letter;

      const getQualityCheck = {
        value: editSamplingPlan.type_name.id,
        label: editSamplingPlan.type_name?.qc_batch?.batch_code,
      };
      defaultValues.type_name_id = getQualityCheck;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setQualityCheck(data?.quality_check);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Sampling Plan </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Minimum Lot Size
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter minimum lot size"
                    id="text-forms-minimum-lot-size"
                    {...register("minimum_lot_size", { required: true })}
                  />
                  {errors.minimum_lot_size && (
                    <span className="fs-8 text-danger">
                      Minimum Lot Size is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Maximum Lot Size
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter maximum lot size"
                    id="text-forms-maximum-lot-size"
                    {...register("maximum_lot_size", { required: true })}
                  />
                  {errors.maximum_lot_size && (
                    <span className="fs-8 text-danger">
                      Maximum Lot Size is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sample Size
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter sample size"
                    id="text-forms-sample-size"
                    {...register("sample_size", { required: true })}
                  />
                  {errors.sample_size && (
                    <span className="fs-8 text-danger">
                      Sample size is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Quality Check Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={qualityCheck?.map((api) => ({
                          value: api.id,
                          label: api.type_name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-type_name_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="type_name_id"
                    control={control}
                  />
                  {errors.type_name_id && (
                    <span className="fs-8 text-danger">
                      Quality Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Code Letter
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter code letter"
                    id="text-forms-code-letter"
                    {...register("code_letter", { required: true })}
                  />
                  {errors.code_letter && (
                    <span className="fs-8 text-danger">
                      Code Letter is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Critical Threshold
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Enter critical threshold"
                    id="text-forms-critical_threshold"
                    {...register("critical_threshold", { required: true })}
                  />
                  {errors.critical_threshold && (
                    <span className="fs-8 text-danger">
                      Critical Threshold is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Major Threshold<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Enter major threshold"
                    id="text-forms-major_threshold"
                    {...register("major_threshold", { required: true })}
                  />
                  {errors.major_threshold && (
                    <span className="fs-8 text-danger">
                      Major Threshold is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Minor Threshold<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Enter minor threshold"
                    id="text-forms-minor_threshold"
                    {...register("minor_threshold", { required: true })}
                  />
                  {errors.minor_threshold && (
                    <span className="fs-8 text-danger">
                      Minor Threshold is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-sampling-plan-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-sampling-plan"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSamplingPlan;
