import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "user",
    header: "User",
    cell: (row) => <>{row?.user?.first_name + " " + row?.user?.last_name}</>,
  },
  {
    accessor: "email",
    header: "Email Address",
    cell: (row) => <>{row?.user?.email}</>,
  },
  {
    accessor: "mob_no",
    header: "Mobile Number",
    cell: (row) => <>{row?.user?.mob_no}</>,
  },
  {
    accessor: "client_location",
    header: "Client Location",
  },
  {
    accessor: "company_name",
    header: "Company Name",
  },
  {
    accessor: "gst_number",
    header: "GST No",
  },
  {
    accessor: "address",
    header: "Address",
  },
  {
    accessor: "gst_certificate",
    header: "GST Certificate",
    cell: (row) => (
      <a target="_blank" href={row?.gst_certificate}>
        GST Certificate
      </a>
    ),
  }
];

const Client = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [clientEntries, setClientEntries] = useState(5);
  const [clientPageIndex, setClientPageIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { useFetch } = useAPIQuery({
    key: "client",
    endpoint: "/api/client/",
  });

  const { data, isLoading } = useFetch({
    params: {
      entries: clientEntries,
      page_no: clientPageIndex + 1,
      event: search ? search : undefined,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime : 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true
  });

  const { useDelete } = useAPIQuery({
    key: "client",
    endpoint: "/api/client/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setClientPageIndex(value.pageIndex);
    setClientEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-client", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Client</h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("client_create") && (
          <div className="nk-block-head-content">
            <NavLink
              to="/create-client"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-client"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-client"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-client"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create Client</span>
            </NavLink>
          </div>)}
        </div>
      </div>

      <Table
        data={data ? data?.results.data : []}
        columns={columns}
        pagination
        pageIndex={clientPageIndex}
        pageSize={clientEntries}
        rowCount={data ? data.count : 0}
        globalSearch
        editRows={privileges.includes("client_update") ? true : false}
        deleteRows={privileges.includes("client_delete") ? true : false}
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default Client;