import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "parameter_name",
    header: "Parameter Name",
    cell: (row) => <>{row?.parameter ? row?.parameter?.parameter_name : "-"}</>,
  },
  {
    accessor: "qc_batch",
    header: "QC batch code",
    cell: (row) => <>{row?.qc_batch ? row?.qc_batch?.batch_code : "-"}</>,
  },
  {
    accessor: "item_id",
    header: "Item Code",
    cell: (row) => <>{row?.item?.item_code ? row?.item?.item_code : "-"}</>,
  },
  {
    accessor: "item_name",
    header: "Item Name",
    cell: (row) => <>{row?.item ? row?.item?.item_name : "-"}</>,
  },
  {
    accessor: "expected_result",
    header: "Expected Result",
  },
  {
    accessor: "actual_result",
    header: "Actual Result",
  },
  {
    accessor: "is_approved",
    header: "Is Approved",
  },
  {
    accessor: "comment",
    header: "Comment",
  },
];

const QualityCheckResults = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [qualityCheckEntries, setQualityCheckEntries] = useState(5);
  const [qualityCheckPageIndex, setQualityCheckPageIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { useFetch } = useAPIQuery({
    key: "quality_check",
    endpoint: "/api/quality-check-results/",
  });

  const { data, isLoading } = useFetch({
    params: {
      entries: qualityCheckEntries,
      page_no: qualityCheckPageIndex + 1,
      event: search ? search : undefined,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
  });

  const { useDelete } = useAPIQuery({
    key: "quality_check",
    endpoint: "/api/quality-check-results/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setQualityCheckPageIndex(value.pageIndex);
    setQualityCheckEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-quality-check-results", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Quality Check</h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("quality_check_create") && (
            <div className="nk-block-head-content">
              <NavLink
                to="/create-quality-check"
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-create-quality-check"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to="/create-quality-check"
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-create-quality-check"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Quality Check</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <Table
        data={data ? data.results.data : []}
        columns={columns}
        pagination
        pageIndex={qualityCheckPageIndex}
        pageSize={qualityCheckEntries}
        rowCount={data ? data.count : 0}
        globalSearch
        editRows={
          privileges.includes("quality_check_update") ? true : false
        }
        deleteRows={
          privileges.includes("quality_check_delete") ? true : false
        }
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default QualityCheckResults;