import React from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const QualitySampleSize = () => {
  const navigate = useNavigate();
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isValid } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Sample Size of - Item 1 </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sample Quantity
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter sample quantity"
                    id="text-forms-sample-quantity"
                    {...register("sample_quantity", { required: true })}
                  />
                  {errors.sample_quantity && (
                    <span className="fs-8 text-danger">Sample quantity is required</span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sample Size
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter sample size"
                    id="text-forms-sample-size"
                    {...register("sample_size", { required: true })}
                  />
                  {errors.sample_size && (
                    <span className="fs-8 text-danger">Sample Size is required</span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {/* {isPending ? (
                      <button
                        type="button"
                        id="button-typemaster-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : ( */}
                    <button
                      type="submit"
                      className="btn btn-md btn-primary"
                      id="submit-button-create-typemaster"
                      disabled={!isValid}
                    >
                      Submit
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualitySampleSize;
