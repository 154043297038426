import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const CreateSupplier = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();

  // select dropdown
  const [item, setItem] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useCreate } = useAPIQuery({
    key: "supplier",
    endpoint: "/api/supplier/",
  });

  const handleCreateSupplier = (data) => {
    Swal.fire({
      title: "Supplier Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorSupplier = (data) => {
    Swal.fire({
      title: "Error Adding Supplier",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateSupplier,
    onError: handleErrorSupplier,
    retry: 2,
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    let supplier = new FormData();
    supplier.append("first_name", data.first_name);
    supplier.append("last_name", data.last_name);
    supplier.append("email", data.email);
    supplier.append("item_list", data.item_id.map(api => api.value));
    supplier.append("gst_number", data.gst_no);
    supplier.append("gst_certificate", data.gst_cert);
    supplier.append("address", data.address);
    supplier.append("created_by", userID);
    supplier.append("updated_by", userID);

    for (var pair of supplier.entries()) {
      console.log(pair);
    }

    mutate({
      data: supplier,
    });
  };

  useEffect(() => {
    if (data) {
      setItem(data.item.filter((api) => api.category !== "FG"));
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Supplier </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    First Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter first name"
                    id="text-forms-first_name"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name && (
                    <span className="fs-8 text-danger">
                      First Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Last Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                    id="text-forms-last_name"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name && (
                    <span className="fs-8 text-danger">
                      Last Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Email Address<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter email address"
                    id="text-forms-email"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="fs-8 text-danger">
                      Email Address is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    GST Number<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter gst number"
                    id="text-forms-gst_no"
                    {...register("gst_no", { required: true })}
                  />
                  {errors.gst_no && (
                    <span className="fs-8 text-danger">
                      GST number is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    GST Certificate<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    name="gst_cert"
                    control={control}
                    rules={{
                      required: true,
                      // validate: (value) => {
                      //   const fileType = value?.[1]?.type;
                      //   return (
                      //     fileType === "application/pdf"
                      //   );
                      // },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-gst_cert"
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files[0]);
                        }}
                      />
                    )}
                  />
                  {errors.gst_cert && (
                    <span className="fs-8 text-danger">
                      GST Certificate is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item
                    {/* <span className="fs-8 text-danger"> *</span> */}
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((api) => {
                          return { value: api.id, label: api.item_name };
                        })}
                        isMulti
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item_id"
                      />
                    )}
                    defaultValue=""
                    // rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {/* {errors.item_id && (
                    <span className="fs-8 text-danger">Item is required</span>
                  )} */}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Address<span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter your address"
                    id="text-forms-address"
                    {...register("address", { required: true })}
                  ></textarea>
                  {errors.address && (
                    <span className="fs-8 text-danger">
                      Address is required
                    </span>
                  )}
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-supplier-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-supplier"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSupplier;
