import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditSupplierItemAssociation = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // select dropdown
  const [item, setItem] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "supplier-item",
    endpoint: "/api/supplier-item-association/",
  });

  const handleUpdateSupplierItemAssoc = (data) => {
    Swal.fire({
      title: "Supplier Item Association Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorSupplierItemAssoc = (data) => {
    Swal.fire({
      title: "Error Updating Supplier Item Association",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateSupplierItemAssoc,
    onError: handleErrorSupplierItemAssoc,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var supplier_item = {
      item_id: data.item_id.value,
      supplier_id: data.supplier_id.value,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: supplier_item,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editSupplierItem = location.state.editpost;
    // defaultValues is a react form hook.
    if (editSupplierItem) {
      var defaultValues = {};
      const getSupplier = {
        value: editSupplierItem.supplier?.id,
        label:
          editSupplierItem.supplier?.user?.first_name +
          " " +
          editSupplierItem.supplier?.user?.last_name,
      };
      defaultValues.supplier_id = getSupplier;

      const getItem = {
        value: editSupplierItem.item?.id,
        label: editSupplierItem.item?.item_name,
      };
      defaultValues.item_id = getItem;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setItem(data.item);
      setSupplier(data.supplier);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Supplier Item Association </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Supplier <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={supplier?.map((api) => {
                          return {
                            value: api.id,
                            label: api?.first_name + " " + api?.last_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-supplier"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="supplier_id"
                    control={control}
                  />
                  {errors.supplier_id && (
                    <span className="fs-8 text-danger">
                      Supplier Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((api) => {
                          return {
                            value: api.id,
                            label: api?.item_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-client-item-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-item-supplier"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSupplierItemAssociation;
