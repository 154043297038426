import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const CreateQualityParameter = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();

  // select dropdown
  const [unit, setUnit] = useState([]);
  const [item, setItem] = useState([]);
  const [specificationType, setSpecificationType] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useCreate } = useAPIQuery({
    key: "quality_parameter",
    endpoint: "/api/quality-parameter/",
  });

  const handleCreateQualityParameter = (data) => {
    Swal.fire({
      title: "Quality Parameter Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorQualityParameter = (data) => {
    Swal.fire({
      title: "Error Adding Quality Parameter",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateQualityParameter,
    onError: handleErrorQualityParameter,
    retry : 2
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var quality_parameter = {
      parameter_name: data.parameter_name,
      unit: data.unit?.value,
      rule_type: data.rule_type,
      item_id: data.item_id?.value,
      specifications_type: data.specifications_type.value,
      created_by : userID,
      updated_by : userID
    };

    mutate({
      data: quality_parameter,
    });
  };

  useEffect(() => {
    if (data) {
      setUnit(
        data?.typemaster?.filter(
          (post) => post.category_value == "quality_unit"
        )
      );
      setSpecificationType(
        data?.typemaster?.filter(
          (post) => post.category_value == "specification_type"
        )
      );
      setItem(data.item);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Quality Parameter </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Parameter Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter parameter name"
                    id="text-forms-parameter-name"
                    {...register("parameter_name", { required: true })}
                  />
                  {errors.parameter_name && (
                    <span className="fs-8 text-danger">
                      Parameter Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Unit
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={unit?.map((info) => ({
                          value: info.name,
                          label: info.name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-unit"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="unit"
                    control={control}
                  />
                  {errors.unit && (
                    <span className="fs-8 text-danger">
                      Unit is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Ryle Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter rule type"
                    id="text-forms-rule-type"
                    {...register("rule_type", { required: true })}
                  />
                  {errors.rule_type && (
                    <span className="fs-8 text-danger">
                      Rule Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((info) => ({
                          value: info.item?.id,
                          label: info.item?.item_name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item-id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Specification Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={specificationType?.map((info) => ({
                          value: info.name,
                          label: info.name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-specification-type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="specifications_type"
                    control={control}
                  />
                  {errors.specifications_type && (
                    <span className="fs-8 text-danger">
                      Specification Type is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-specification-type-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-specification-type"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQualityParameter;