import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "item_name",
    header: "Item Name",
    cell: (row) => (
      <>
        {row?.item?.item_name ? row?.item?.item_name : "-"}
      </>
    ),
  },
  {
    accessor: "item_code",
    header: "Item Code",
    cell: (row) => (
      <>
        {row?.item?.item_code ? row?.item?.item_code : "-"}
      </>
    ),
  },
  {
    accessor: "quality_rule_parameter",
    header: "Quality Rule Parameter",
    cell: (row) => <>{row?.rule?.parameter ? row?.rule?.parameter?.parameter_name : "-"}</>,
  },
  {
    accessor: "quality_rule_value",
    header: "Quality Rule Value",
    cell: (row) => <>{row?.rule?.parameter ? row?.rule?.parameter?.rule_type : "-"}</>,
  },
  {
    accessor: "critical_threshold",
    header: "Critical Threshold",
  },
  {
    accessor: "major_threshold",
    header: "Major Threshold",
  },
  {
    accessor: "minor_threshold",
    header: "Minor Threshold",
  }
];

const QualityRuleAssociation = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [associationEntries, setAssociationEntries] = useState(5);
  const [associationPageIndex, setAssociationPageIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { useFetch } = useAPIQuery({
    key: "quality-rule-assoc",
    endpoint: "/api/quality-rule-association/",
  });

  const { data, isLoading } = useFetch({
    params: {
      entries: associationEntries,
      page_no: associationPageIndex + 1,
      event: search ? search : undefined,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime : 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true
  });

  const { useDelete } = useAPIQuery({
    key: "quality-rule-assoc",
    endpoint: "/api/quality-rule-association/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setAssociationPageIndex(value.pageIndex);
    setAssociationEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-quality-rule-association", {
      state: { editpost: row.original },
    });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Quality Parameter - Rule Association
            </h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("quality_rule_association_create") && (
            <div className="nk-block-head-content">
              <NavLink
                to="/create-quality-rule-association"
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-create-quality-rule-association"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to="/create-quality-rule-association"
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-create-quality-rule-association"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Quality-Rule Association</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <Table
        data={data ? data?.results.data : []}
        columns={columns}
        pagination
        pageIndex={associationPageIndex}
        pageSize={associationEntries}
        rowCount={data ? data.count : 0}
        globalSearch
        editRows={
          privileges.includes("quality_rule_association_update") ? true : false
        }
        deleteRows={
          privileges.includes("quality_rule_association_delete") ? true : false
        }
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default QualityRuleAssociation;