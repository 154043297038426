import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Sidebar = () => {
  const privileges = secureLocalStorage.getItem("privileges");

  const [sidebarActive, setSidebarActive] = useState(false);
  const handleActive = (status) => {
    setSidebarActive(status);
  };

  const [sidebarMasterActive, setSidebarMasterActive] = useState(false);
  const handleMasterActive = (status) => {
    setSidebarMasterActive(status);
  };

  return (
    <>
      <div
        className="nk-sidebar is-light nk-sidebar-fixed is-light"
        data-content="sidebarMenu"
      >
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <a href="javascript:void(0)" className="logo-link nk-sidebar-logo">
              <img
                className="logo-light logo-img"
                src="./assets/images/gev-logo-dark.png"
                srcSet="./assets/images/gev-logo-dark.png 2x"
                alt="gev-logo"
              />
              <img
                className="logo-dark logo-img"
                src="./assets/images/gev-logo-dark.png"
                srcSet="./assets/images/gev-logo-dark.png 2x"
                alt="gev-logo"
              />
              <img
                className="logo-small logo-img logo-img-small"
                src="./assets/images/gev-logo-dark.png"
                srcSet="./assets/images/gev-logo-dark.png 2x"
                alt="gev-logo"
              />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>

        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                <li className="nk-menu-item">
                  {[
                    "sales_order_retrieve",
                    "sales_order_create",
                    "sales_order_update",
                    "sales_order_delete",
                    "work_order_split",
                    "approve_sales_order",
                  ].some((privilege) => privileges.includes(privilege)) && (
                    <NavLink to="/" className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-tag"></em>
                      </span>
                      <span className="nk-menu-text">Sales Order</span>
                    </NavLink>
                  )}
                </li>
                <li className="nk-menu-item">
                  {[
                    "work_order_retrieve",
                    "work_order_create",
                    "work_order_update",
                    "work_order_delete",
                  ].some((privilege) => privileges.includes(privilege)) && (
                    <NavLink to="/work-order" className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-cc"></em>
                      </span>
                      <span className="nk-menu-text">Work Order</span>
                    </NavLink>
                  )}
                </li>
                <li className="nk-menu-item">
                  {privileges.includes("daily_plan") && (
                    <NavLink to="/daily-plan" className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-tag"></em>
                      </span>
                      <span className="nk-menu-text">Daily Plan</span>
                    </NavLink>
                  )}
                </li>

                {privileges.includes("master") && (
                  <li
                    className={`${
                      sidebarMasterActive
                        ? "nk-menu-item has-sub active"
                        : "nk-menu-item has-sub"
                    }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleMasterActive(!sidebarMasterActive)}
                      id="sidebar-setting"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-external"></em>
                      </span>
                      <span className="nk-menu-text">Master</span>
                    </a>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        {[
                          "item_retrieve",
                          "item_create",
                          "item_update",
                          "item_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/item" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-card-view"></em>
                            </span> */}
                            <span className="nk-menu-text">Item</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "bom_retrieve",
                          "bom_create",
                          "bom_update",
                          "bom_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/bom" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-file-text"></em>
                            </span> */}
                            <span className="nk-menu-text">BOM</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "client_retrieve",
                          "client_create",
                          "client_update",
                          "client_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/client" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-users"></em>
                            </span> */}
                            <span className="nk-menu-text">Client</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "supplier_retrieve",
                          "supplier_create",
                          "supplier_update",
                          "supplier_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/supplier" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-card-view"></em>
                            </span> */}
                            <span className="nk-menu-text">Supplier</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "client_item_retrieve",
                          "client_item_create",
                          "client_item_update",
                          "client_item_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/client-item" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-card-view"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Client-Item Association
                            </span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "supplier_item_retrieve",
                          "supplier_item_create",
                          "supplier_item_update",
                          "supplier_item_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/supplier-item" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-card-view"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Supplier-Item Association
                            </span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "operator_retrieve",
                          "operator_create",
                          "operator_update",
                          "operator_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/operator" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em class="icon ni ni-newspaper"></em>
                            </span> */}
                            <span className="nk-menu-text">Operator</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "daily_production_retrieve",
                          "daily_production_create",
                          "daily_production_update",
                          "daily_production_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/daily-production-report"
                            className="nk-menu-link"
                          >
                            {/* <span className="nk-menu-icon">
                              <em class="icon ni ni-newspaper"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Daily production report
                            </span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "machine_retrieve",
                          "machine_create",
                          "machine_update",
                          "machine_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/machine" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-card-view"></em>
                            </span> */}
                            <span className="nk-menu-text">Machine</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "machine_status_retrieve",
                          "machine_status_create",
                          "machine_status_update",
                          "machine_status_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/machine-status"
                            className="nk-menu-link"
                          >
                            {/* <span className="nk-menu-icon">
                              <em class="icon ni ni-newspaper"></em>
                            </span> */}
                            <span className="nk-menu-text">Machine Status</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "stock_transaction_retrieve",
                          "stock_transaction_create",
                          "stock_transaction_update",
                          "stock_transaction_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/stock-transaction"
                            className="nk-menu-link"
                          >
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-tranx"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Stock Transaction
                            </span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "bo_retrieve",
                          "bo_create",
                          "bo_update",
                          "bo_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/bo" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em class="icon ni ni-newspaper"></em>
                            </span> */}
                            <span className="nk-menu-text">BO</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "sampling_plan_retrieve",
                          "sampling_plan_create",
                          "sampling_plan_update",
                          "sampling_plan_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/operator" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em class="icon ni ni-newspaper"></em>
                            </span> */}
                            <span className="nk-menu-text">Operator</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "quality_rules_retrieve",
                          "quality_rules_create",
                          "quality_rules_update",
                          "quality_rules_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/quality-rules" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-tranx"></em>
                            </span> */}
                            <span className="nk-menu-text">Quality Rules</span>
                          </NavLink>
                        )}
                      </li>

                      <li className="nk-menu-item">
                        {[
                          "quality_parameter_retrieve",
                          "quality_parameter_create",
                          "quality_parameter_update",
                          "quality_parameter_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/quality-parameter"
                            className="nk-menu-link"
                          >
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-tranx"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Quality Parameter
                            </span>
                          </NavLink>
                        )}
                      </li>

                      <li className="nk-menu-item">
                        {[
                          "quality_check_retrieve",
                          "quality_check_create",
                          "quality_check_update",
                          "quality_check_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink to="/quality-check" className="nk-menu-link">
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-tranx"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Quality Check result
                            </span>
                          </NavLink>
                        )}
                      </li>

                      <li className="nk-menu-item">
                        {[
                          "quality_rule_association_retrieve",
                          "quality_rule_association_create",
                          "quality_rule_association_update",
                          "quality_rule_association_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/quality-rule-association"
                            className="nk-menu-link"
                          >
                            {/* <span className="nk-menu-icon">
                              <em className="icon ni ni-tranx"></em>
                            </span> */}
                            <span className="nk-menu-text">
                              Quality Parameter Rule Association
                            </span>
                          </NavLink>
                        )}
                      </li>
                    </ul>
                    {/* <!-- .nk-menu-sub --> */}
                  </li>
                )}
                {privileges.includes("setting") && (
                  <li
                    className={`${
                      sidebarActive
                        ? "nk-menu-item has-sub active"
                        : "nk-menu-item has-sub"
                    }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleActive(!sidebarActive)}
                      id="sidebar-setting"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-setting"></em>
                      </span>
                      <span className="nk-menu-text">Settings</span>
                    </a>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        {[
                          "privilege_retrieve",
                          "privilege_create",
                          "privilege_update",
                          "privilege_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/privilege"
                            className="nk-menu-link"
                            id="sidebar-privilege"
                          >
                            <span className="nk-menu-text">Privileges</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "role_retrieve",
                          "role_create",
                          "role_update",
                          "role_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/role"
                            className="nk-menu-link"
                            id="sidebar-role"
                          >
                            <span className="nk-menu-text">Role</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "type_master_retrieve",
                          "type_master_create",
                          "type_master_update",
                          "type_master_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/type-master"
                            className="nk-menu-link"
                            id="sidebar-type-master"
                          >
                            <span className="nk-menu-text">Type Master</span>
                          </NavLink>
                        )}
                      </li>
                      <li className="nk-menu-item">
                        {[
                          "type_master_category_retrieve",
                          "type_master_category_create",
                          "type_master_category_update",
                          "type_master_category_delete",
                        ].some((privilege) =>
                          privileges.includes(privilege)
                        ) && (
                          <NavLink
                            to="/type-master-category"
                            className="nk-menu-link"
                            id="sidebar-type-master-category"
                          >
                            <span className="nk-menu-text">
                              Type Master Category
                            </span>
                          </NavLink>
                        )}
                      </li>
                    </ul>
                    {/* <!-- .nk-menu-sub --> */}
                  </li>
                )}
                {/* <!-- .nk-menu-item --> */}
              </ul>
              {/* <!-- .nk-menu --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;