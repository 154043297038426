import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useDailyPlanCalendar from "./useDailyPlanCalendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-loading-skeleton/dist/skeleton.css";
import AssignWoModal from "./modal/AssignWOModal";
import EditWOModal from "./modal/EditWoModal";
import LoadingCalendar from "./LoadingCalendar";
import ErrorCalendar from "./ErrorCalendar";

const localizer = momentLocalizer(moment);

const DailyPlanCalendar = ({ machine_id, wip, activeAccordion }) => {
    // Review - Vaishnavi - 22/01/2025 - Please add comments in complex structure
    // importing states and function from useDailyPlanCalendar custom hook
    const {
        woEvents,
        currentWO,
        handleSelectEvent,
        handleSelectSlot,
        handleWOSelect,
        handleWOEdit,
        handleWORemove,
        setCurrentDate,
        loadingMachineInfoData,
        loadingWorkOrdersData,
        errorLoadingWorkOrders,
        errorLoadingMachineInfo,
    } = useDailyPlanCalendar({
        machine_id: machine_id,
        wip: { wip },
        activeAccordion: activeAccordion,
    });
    if (loadingMachineInfoData || loadingWorkOrdersData)
        return <LoadingCalendar />;
    if (errorLoadingMachineInfo || errorLoadingWorkOrders)
        // Review - vaishnavi - 22/01/2025 - We can call here Error Page rather than creating a new Error Page.
        return <ErrorCalendar />;
    return (
        <div className="h-100">
            <Calendar
                localizer={localizer}
                defaultView="month"
                views={["month"]}
                events={woEvents}
                selectable
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                dayPropGetter={(date) => {
                    if (
                        new Date(date).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0)
                    ) {
                        return {
                            style: {
                                backgroundColor: "#e6e6e6",
                                cursor: "not-allowed",
                            },
                        };
                    }
                    return {};
                }}
                onNavigate={(date) => setCurrentDate(date)}
            />
            <AssignWoModal
                machine_id={machine_id}
                handleWOSelect={handleWOSelect}
            />
            <EditWOModal
                machine_id={machine_id}
                handleWOEdit={handleWOEdit}
                handleWORemove={handleWORemove}
                currentWO={currentWO}
            />
        </div>
    );
};

export default DailyPlanCalendar;
