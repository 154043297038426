import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constant";

const EditTypeMaster = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  const [categoryData, setCategoryData] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "type_master",
    endpoint: "/api/type-master/",
  });

  const handleUpdateTypeMaster = (data) => {
    Swal.fire({
      title: "Type Master Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorTypeMaster = (data) => {
    Swal.fire({
      title: "Error Updating Type Master",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateTypeMaster,
    onError: handleErrorTypeMaster,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var type_master = {
      name: data.name,
      value: data.value,
      category_id: data.category.value,
      description: data.description,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: type_master,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editTypemaster = location.state.editpost;
    // defaultValues is a react form hook.
    if (editTypemaster) {
      var defaultValues = {};
      defaultValues.name = editTypemaster.name;
      defaultValues.value = editTypemaster.value;
      defaultValues.description = editTypemaster.description;

      const getCategory = {
        value: editTypemaster.category?.id,
        label: editTypemaster.category?.category_name,
      };
      defaultValues.category = getCategory;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setCategoryData(data.category);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Type Master </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter type master display name"
                    id="text-forms-name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="fs-8 text-danger">
                      Type Master Display Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Enter type master name"
                    id="email-forms-value"
                    {...register("value")}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Category
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={categoryData?.map((info) => ({
                          value: info.id,
                          label: info.category_name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-typemaster-category"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Type Master Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Description<span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Description"
                    id="textarea-forms-description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="fs-8 text-danger">
                      Description is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-typemaster-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-typemaster"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTypeMaster;
