import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditQualityRules = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // select dropdown
  const [parameter, setParameter] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "quality_rules",
    endpoint: "/api/quality-rules/",
  });

  const handleUpdateQualityRules = (data) => {
    Swal.fire({
      title: "Quality Rules Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorQualityRules = (data) => {
    Swal.fire({
      title: "Error Updating Quality Rules",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateQualityRules,
    onError: handleErrorQualityRules,
    retry: 2,
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var quality_rules = {
      parameter: data.parameter.value,
      lower_limit: data.lower_limit,
      higher_limit: data.higher_limit,
      tolerance: data.tolerance,
      rule_func: data.rule_func,
      arguments: data.arguments,
      expected_result: data.expected_result,
      updated_by: userID,
    };

    mutate({
      id: location.state.editpost.id,
      data: quality_rules,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editQuality = location.state.editpost;
    // defaultValues is a react form hook.
    if (editQuality) {
      var defaultValues = {};
      defaultValues.lower_limit = editQuality.lower_limit;
      defaultValues.higher_limit = editQuality.higher_limit;
      defaultValues.tolerance = editQuality.tolerance;
      defaultValues.rule_func = editQuality.rule_func;
      defaultValues.arguments = editQuality.arguments;
      defaultValues.expected_result = editQuality.expected_result;

      const getParameter = {
        value: editQuality.parameter?.id,
        label: editQuality.parameter?.parameter_name,
      };
      defaultValues.parameter = getParameter;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setParameter(data.parameters);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Quality Rules </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Parameter
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={parameter?.map((info) => ({
                          value: info?.parameter?.id,
                          label: info?.parameter?.parameter_name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-parameter"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="parameter"
                    control={control}
                  />
                  {errors.parameter && (
                    <span className="fs-8 text-danger">
                      Parameter is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Lower Limit
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Enter lower limit value"
                    id="text-forms-lower_limit"
                    {...register("lower_limit", { required: true })}
                  />
                  {errors.lower_limit && (
                    <span className="fs-8 text-danger">
                      Lower Limit is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Higher Limit
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Enter higher limit value"
                    id="text-forms-higher_limit"
                    {...register("higher_limit", { required: true })}
                  />
                  {errors.higher_limit && (
                    <span className="fs-8 text-danger">
                      Higher Limit is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Tolerance<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Enter tolerance level"
                    id="email-forms-tolerance"
                    {...register("tolerance", { required: true })}
                  />
                  {errors.tolerance && (
                    <span className="fs-8 text-danger">
                      Tolerance is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Rule Function<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter rule function"
                    id="email-forms-rule_func"
                    {...register("rule_func", { required: true })}
                  />
                  {errors.rule_func && (
                    <span className="fs-8 text-danger">
                      Rule Function is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Arguments<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter arguments"
                    id="email-forms-arguments"
                    {...register("arguments", { required: true })}
                  />
                  {errors.arguments && (
                    <span className="fs-8 text-danger">
                      Arguments is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Expected result<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter excepted result"
                    id="email-forms-expected_result"
                    {...register("expected_result", { required: true })}
                  />
                  {errors.expected_result && (
                    <span className="fs-8 text-danger">
                      Expected Result is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-quality-rules-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-quality-rules"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditQualityRules;
