import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditMachineStatus = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // select dropdown
  const [machine, setMachine] = useState([]);
  const [machineStatus, setMachineStatus] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useUpdate } = useAPIQuery({
    key: "machine_status",
    endpoint: "/api/machine-status/",
  });

  const handleUpdateMachineStatus = (data) => {
    Swal.fire({
      title: "Machine Status Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorMachineStatus = (data) => {
    Swal.fire({
      title: "Error Updating Machine Status",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateMachineStatus,
    onError: handleErrorMachineStatus,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var machine_status = {
      status_date: data.status_date,
      machine_id: data.machine_id.value,
      machine_status: data.machine_status.value,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: machine_status,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editMachineStatus = location.state.editpost;
    // defaultValues is a react form hook.
    if (editMachineStatus) {
      var defaultValues = {};
      defaultValues.status_date = editMachineStatus.status_date;

      const getMachine = {
        value: editMachineStatus.machine?.id,
        label: editMachineStatus.machine?.department,
      };
      defaultValues.machine_id = getMachine;

      const getMachineStatus = {
        value: editMachineStatus.machine_status,
        label: editMachineStatus.machine_status,
      };
      defaultValues.machine_status = getMachineStatus;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setMachine(data.machine);
      setMachineStatus(
        data?.typemaster?.filter(
          (post) => post.category_value == "machine_status"
        )
      );
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Machine Status</h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Machine <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={machine?.map((api) => {
                          return {
                            value: api.id,
                            label: api.department,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-machine"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="machine_id"
                    control={control}
                  />
                  {errors.machine_id && (
                    <span className="fs-8 text-danger">
                      Machine Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Machine Status <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={machineStatus?.map((api) => {
                          return {
                            value: api.name,
                            label: api.name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-machine_status"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="machine_status"
                    control={control}
                  />
                  {errors.machine_status && (
                    <span className="fs-8 text-danger">
                      Machine Status is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Status Date<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="text-forms-status_date"
                    {...register("status_date", { required: true })}
                  />
                  {errors.status_date && (
                    <span className="fs-8 text-danger">
                      Status Date is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-machine-status-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-machine-status"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMachineStatus;