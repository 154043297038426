import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm, useFormState } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { GlobalContext } from "../../Context/GlobalContext";
import { VARIABLES } from "../../Constant";
import secureLocalStorage from "react-secure-storage";

const CreateSalesOrder = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();

  // dropdown
  const [productData, setProductData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const { cityLocation } = useContext(GlobalContext);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useFetch: fetchClientProductAssociation } = useAPIQuery({
    key: "client-product",
    endpoint: "/api/client-item-association/",
  });

  const { data: clientDropdown, isLoading: isClientLoading } =
    fetchClientProductAssociation({
      abortOnNewRequest: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 3,
      retry: 3,
      keepPlaceholder: true,
      refetchOnReconnect: true,
    });

  console.log(clientDropdown);

  const { useCreate } = useAPIQuery({
    key: "sales_order",
    endpoint: "/api/sales-order/",
  });

  const handleCreateSalesOrder = (data) => {
    Swal.fire({
      title: "Sales Order Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorSalesOrder = (data) => {
    Swal.fire({
      title: "Error Adding Type Master",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateSalesOrder,
    onError: handleErrorSalesOrder,
    retry: 2,
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const [selectedProduct, setSelectedProduct] = useState(null);
  console.log(selectedProduct);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    const formattedDate = data.target_date
      ? format(new Date(data.target_date), "yyyy-MM-dd")
      : null;
    console.log(data);
    var sale_order = {
      product_id: data.product.value,
      client_id: clientData?.client?.id,
      target_date: formattedDate,
      dispatch_location: data.dispatch_location.value,
      quantity: data.quantity,
      activity_status: 2, // Sales order as draft
      created_by: userID,
      updated_by: userID,
    };
    console.log(sale_order);

    mutate({
      data: sale_order,
    });
  };

  useEffect(() => {
    if (data) {
      setProductData(data.item.filter((api) => api.category === "FG"));
    }
  }, [data]);

  useEffect(() => {
    if (clientDropdown) {
      setClientData(
        clientDropdown.results.data.find((api) => {
          return api.item?.id === selectedProduct?.value;
        })
      );
    }
  }, [clientDropdown, selectedProduct]);

  console.log(clientData);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Sales Order </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Product Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={productData.map((api) => {
                          return {
                            label: api.item_name,
                            value: api.id,
                            item_code: api.item_code,
                            bom_code: api.bom_code,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-product"
                        onChange={(e) => {
                          setSelectedProduct(e);
                          field.onChange(e);
                        }}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="product"
                    control={control}
                  />
                  {errors.product && (
                    <span className="fs-8 text-danger">
                      Product Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label"> Product ID </label>
                  <div className="text-dark fw-bold fs-6">
                    {selectedProduct
                      ? selectedProduct.item_code
                      : "Please select Product first"}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label"> BOM ID </label>
                  <div className="text-dark fw-bold fs-6">
                    {selectedProduct
                      ? selectedProduct.bom_code
                      : "Please select Product first"}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">Client Name</label>
                  <div className="text-dark fw-bold fs-6">
                    {clientData
                      ? clientData.client?.user?.first_name +
                        " " +
                        clientData.client?.user?.last_name
                      : "Please select Product first"}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Quantity
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter no of quantity"
                    id="number-forms-quantity"
                    {...register("quantity", {
                      required: "Quantity is required",
                      validate: {
                        positive: (value) =>
                          value > 0 || "Only positive numbers are allowed",
                        integer: (value) =>
                          Number.isInteger(parseFloat(value)) ||
                          "Only integers are allowed",
                      },
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.quantity && (
                    <span className="fs-8 text-danger">
                      {errors.quantity.message}
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Target Date
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <div>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <Controller
                          render={({ field }) => (
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Target date"
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              minDate={new Date()} // Can't select past dates
                              maxDate={new Date("2030-12-31")} // Set max date
                            />
                          )}
                          defaultValue=""
                          rules={{ required: true }}
                          name="target_date"
                          control={control}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            <em class="icon ni ni-calendar-check-fill"></em>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.target_date && (
                    <span className="fs-8 text-danger">
                      Target Date is required
                    </span>
                  )}
                  {/* <input
                    type="date"
                    className="form-control"
                    id="email-forms-target_date"
                    {...register("target_date", {
                      required: true,
                    })}
                  /> */}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Dispatch Location
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={cityLocation.map((api) => {
                          return {
                            label: api,
                            value: api,
                          };
                        })}
                        isClearable={true}
                        id="select-forms-dispatch_location"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="dispatch_location"
                    control={control}
                  />
                  {errors.dispatch_location && (
                    <span className="fs-8 text-danger">
                      Dispatch Location is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-sales-order-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-sales-order"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSalesOrder;
