import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const CheckBOM = ({ item_id, wo_id, salesorder_id, disabled }) => {
  console.log(disabled);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const columns = [
    {
      accessor: "item_code",
      header: "Item Id",
    },
    {
      accessor: "item_name",
      header: "Item Name",
    },
    {
      accessor: "category",
      header: "Category",
    },
    {
      accessor: "item_per_product",
      header: "Item/Pro",
    },
    {
      accessor: "allotted_stock",
      header: "Allotted Stock",
    },
    {
      accessor: "work_order_req_quantity",
      header: "Work Order Require Quantity",
    },
    {
      accessor: "uom",
      header: "UOM",
    },
    {
      accessor: "allocate",
      header: "Allocate",
      cell: (row) => (
        <>
          {row.category === "WIP" ? (
            <>
              <input
                type="number"
                className="form-control form-control-sm"
                {...register(`allot-${row?.id}`, {
                  valueAsNumber: true, // Ensure the value is treated as a number
                  required: true, // Make the input required if necessary
                  validate: (value) =>
                    value <= row?.available ||
                    `Value cannot exceed ${row?.available}`,
                })}
                defaultValue={row?.available} // Set default value
              />
              {errors[`allot-${row?.id}`]?.message && (
                <span className="fs-8 text-danger mt-2">
                  {errors[`allot-${row?.id}`]?.message}
                </span>
              )}{" "}
            </>
          ) : (
            row?.available
          )}
        </>
      ),
    },
  ];

  const [wips, setWips] = useState([]);
  const [rms, setRms] = useState([]);

  // To Allot the WIP Starts here
  const { useCreate: createAllotWIP } = useAPIQuery({
    key: "updateBOM",
    endpoint: `/api/allot-wip-rm/`,
  });

  const handleAllotWIPSuccess = (data) => {
    Swal.fire({
      title: "WIP Allotted Successfully",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {}, 2000);
      }
    });
  };

  const handleAllotWIPError = (data) => {
    Swal.fire({
      title: "Error Allotting WIP",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate: mutateAllotWIP, isPending } = createAllotWIP({
    onSuccess: handleAllotWIPSuccess,
    onError: handleAllotWIPError,
    retry : 2
  });
  // To Allot the WIP Ends here

  // Fetch the WIP and RM starts here
  const { useCreate } = useAPIQuery({
    key: "checkBOM",
    endpoint: `/api/check-bom/`,
  });

  const handleSuccessCheckBOM = (data) => {
    const wipsAva = data?.data?.filter(
      (item) => item?.item?.category === "WIP"
    );
    const rmAva = data?.data?.filter(
      (item) => item?.item?.category === "Raw Material"
    );
    setWips(
      wipsAva.map((wip) => ({
        id: wip?.id,
        item_code: wip?.item?.item_code,
        item_id: wip?.item?.id,
        item_name: wip?.item?.item_name,
        category: wip?.item?.category,
        allotted_stock : wip?.allotted_stock,
        work_order_req_quantity : wip?.work_order_req_quantity,
        item_per_product: wip?.bom?.item_per_product,
        uom: wip?.item?.uom,
        available: (wip?.item?.stock_quantity ?? 0) - (wip?.allotted_stock ?? 0),
      }))
    );
    setRms(
      rmAva.map((rm) => ({
        id: rm?.id,
        item_code: rm?.item?.item_code,
        item_id: rm?.item?.id,
        item_name: rm?.item?.item_name,
        category: rm?.item?.category,
        work_order_req_quantity : rm?.work_order_req_quantity,
        item_per_product: rm?.bom?.item_per_product,
        uom: rm?.item?.uom,
        available: rm?.item?.stock_quantity,
      }))
    );
  };

  const handleErrorCheckBOM = (data) => {
    Swal.fire({
      title: "Error Fetching BOM",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate: mutateCheckBOM } = useCreate({
    onSuccess: handleSuccessCheckBOM,
    onError: handleErrorCheckBOM,
    retry : 2
  });

  const handleCheckBom = () => {
    if (item_id && wo_id) {
      mutateCheckBOM({
        data: { item_id: item_id, work_order_id: wo_id },
      });
    }
  };
  // Fetch the WIP and RM ends here

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleSubmitCheckBOM = () => {
    let values = {};
    values = wips.map((wip) => ({
      id: wip?.id,
      allotted_stock: watch(`allot-${wip.id}`),
      is_stock_allotted: true,
    }));

    console.log(values);
    // values = [
    //   ...values,
    //   ...rms.map((rm) => ({
    //     id: rm.item_id,
    //     allotted_stock: watch(`allot-${rm.id}`),
    //     is_stock_allotted: true,
    //   })),
    // ];
    mutateAllotWIP({
      data: {
        work_order_id: wo_id,
        sales_order_id: salesorder_id,
        // is_locked: true,
        bulk_upload: values,
      },
    });
  };

  return (
    <>
      <div
        className="modal fade m-0 p-0"
        tabIndex={-1}
        id={`check-bom-${wo_id}`}
        aria-modal
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal">
              <em className="icon ni ni-cross" aria-hidden="true"></em>
            </button>
            <div className="modal-header">
              <h5 className="modal-title">Check BOM</h5>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                      <h6 className="nk-block-title" style={{ fontSize : "1.3rem"}}>WIP</h6>
                    </div>
                  </div>
                </div>
                <Table
                    data={wips}
                    columns={columns}
                    pagination={false}
                    selectRows={false}
                    globalSearch={false}
                    filterModal={false}
                    editRows={false}
                    deleteRows={false}
                  />
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                      <h6 className="nk-block-title" style={{ fontSize : "1.3rem"}}>Raw Material</h6>
                    </div>
                  </div>
                </div>
                  <Table
                    data={rms}
                    columns={columns}
                    pagination={false}
                    selectRows={false}
                    globalSearch={false}
                    filterModal={false}
                    editRows={false}
                    deleteRows={false}
                  />
              </div>
              <div className="modal-footer bg-light">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmitCheckBOM}
                  // disabled={!isValid || isPending}
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-sm btn-primary"
        data-bs-toggle="modal"
        data-bs-target={`#check-bom-${wo_id}`}
        onClick={handleCheckBom}
        disabled={disabled}
      >
        check-bom
      </button>
    </>
  );
};

export default CheckBOM;
