import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import secureLocalStorage from "react-secure-storage";

const CreateTypeMasterCategory = () => {
  const userID = secureLocalStorage.getItem("userID");
  const navigate = useNavigate();

  const { useCreate } = useAPIQuery({
    key: "type_master_category",
    endpoint: "/api/type-master-category/",
  });

  const handleCreateTypeCategory = (data) => {
    Swal.fire({
      title: "Type Master Category Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorTypeCategory = (data) => {
    Swal.fire({
      title: "Error Adding Type Master Category",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateTypeCategory,
    onError: handleErrorTypeCategory,
    retry : 2
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var type_master_category = {
      category_name: data.category_name,
      category_value: data.category_value,
      description: data.description,
      created_by : userID,
      updated_by : userID
    };

    mutate({
      data: type_master_category,
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Type Master Category </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Category Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter type master category display name"
                    id="text-forms-category_name"
                    {...register("category_name", { required: true })}
                  />
                  {errors.category_name && (
                    <span className="fs-8 text-danger">
                      Type Master Category Display Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Type Master Category Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter type master category name"
                    id="email-forms-category_value"
                    {...register("category_value", { required: true })}
                  />
                  {errors.category_value && (
                    <span className="fs-8 text-danger">
                      Type Master Category Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Description<span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Description for type master category"
                    id="textarea-forms-description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="fs-8 text-danger">
                      Description is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-type-master-category-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-type-master-category"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTypeMasterCategory;
