import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

const AssignWOModal = ({
    currentWO,
    handleWOEdit,
    handleWORemove,
    machine_id,
}) => {
    const { handleSubmit, register, reset } = useForm();

    const onSubmit = (data) => {
        const date = new Date(data.wodate);
        date.setHours(0, 0, 0, 0);
        // const formattedDate = date.toString();
        const formattedDate = date;

        if (data.woquantity) {
            handleWOEdit(new Date(formattedDate), Number(data.woquantity));
        } else {
            handleWOEdit(new Date(formattedDate));
        }
    };

    useEffect(() => {
        if (currentWO) {
            const formattedDate = moment(currentWO.start).format("YYYY-MM-DD");
            reset({
                wodate: formattedDate,
                woquantity: currentWO.amount,
            });
        }
    }, [currentWO, reset]);

    return (
        <div
            className="modal fade m-0 p-0"
            tabIndex={-1}
            id={`edit-wo-modal-${machine_id}`}
            aria-modal
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <button className="close" data-bs-dismiss="modal">
                        <em
                            className="icon ni ni-cross"
                            aria-hidden="true"
                        ></em>
                    </button>
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Work Order</h5>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="wo">
                                            Work Order Quantity
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("woquantity")}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="wo">
                                            Work Order Date
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            {...register("wodate")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer bg-light">
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                                onClick={handleWORemove}
                            >
                                Remove
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AssignWOModal;
