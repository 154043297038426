import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import { GlobalContext } from '../Context/GlobalContext';

const Header = () => {
  const first_name = secureLocalStorage.getItem("firstName")
  const last_name = secureLocalStorage.getItem("lastName")
  const email = secureLocalStorage.getItem("email")
  const {logoutHandler} = useContext(GlobalContext)
  const navigate = useNavigate()

  const handleLogout = () => {
    logoutHandler();
    navigate("/");
  };

  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1 me-3">
              <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em
                className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="#" className="logo-link">
                <img className="logo-light logo-img" src="./assets/images/gev-logo-light.png" srcSet="./assets/images/gev-logo-light.png 2x" alt="gev-logo" />
                <img className="logo-dark logo-img" src="./assets/images/gev-logo-light.png" srcSet="./assets/images/gev-logo-light.png 2x"
                  alt="gev-logo" />
              </a>
            </div>
            {/* <!-- .nk-header-brand --> */}
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li className="dropdown user-dropdown">
                  <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">
                        <div className="user-avatar">
                          <span>GEV</span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">{first_name + " " + last_name}</span>
                          <span className="sub-text">{email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li><a href="#" onClick={() => handleLogout()}><em className="icon ni ni-signout"></em><span>Sign Out</span></a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- .nk-header-tools --> */}
          </div>
          {/* <!-- .nk-header-wrap --> */}
        </div>
        {/* <!-- .container-fliud --> */}
      </div>
    </>
  )
}

export default Header