import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const CreateProduct = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();

  //dropdown
  const [procurementType, setProcurementType] = useState([]);
  const [materialType, setMaterialType] = useState([]);
  const [subMaterialType, setSubMaterialType] = useState([]);
  const [category, setCategory] = useState([]);
  const [unitMeaurement, setUnitMeaurement] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useCreate } = useAPIQuery({
    key: "item",
    endpoint: "/api/item/",
  });

  const handleCreateItem = (data) => {
    Swal.fire({
      title: "Item Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorItem = (data) => {
    console.log("Error:", data?.data);
    Swal.fire({
      title: "Error Adding Item",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateItem,
    onError: handleErrorItem,
    retry: 2,
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var product = {
      item_name: data.item_name,
      threshold_amount: data.threshold_amount,
      lead_time: data.lead_time,
      procurement_type: data.procurement_type.value,
      material_type: data.material_type.value,
      material_subtype: data.material_subtype.value,
      category: data.category.value,
      stock_quantity: data.stock_quantity,
      uom: data.uom.value,
      created_by: userID,
      updated_by: userID,
    };

    mutate({
      data: product,
    });
  };

  useEffect(() => {
    if (data) {
      setProcurementType(
        data?.typemaster?.filter(
          (post) => post.category_value == "procurement_type"
        )
      );
      setMaterialType(
        data?.typemaster?.filter(
          (post) => post.category_value == "material_type"
        )
      );
      setSubMaterialType(
        data?.typemaster?.filter(
          (post) => post.category_value == "material_sub_type"
        )
      );
      setCategory(
        data?.typemaster?.filter(
          (post) => post.category_value == "item_category"
        )
      );
      setUnitMeaurement(
        data?.typemaster?.filter(
          (post) => post.category_value == "unit_of_measurement"
        )
      );
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Item </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter item name"
                    id="text-forms-item_name"
                    {...register("item_name", {
                      required: true,
                    })}
                  />
                  {errors.item_name && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Threshold Amount
                    {/* <span className="fs-8 text-danger"> *</span> */}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter threshold amount"
                    id="number-forms-threshold_amount"
                    {...register("threshold_amount", {
                      validate: {
                        positive: (value) =>
                          !value ||
                          value > 0 ||
                          "Only positive numbers are allowed",
                        integer: (value) =>
                          !value ||
                          Number.isInteger(parseFloat(value)) ||
                          "Only integers are allowed",
                      },
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.threshold_amount && (
                    <span className="fs-8 text-danger">
                      {errors.threshold_amount.message}
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Lead Time<span className="fs-8 text-danger"> *</span>
                  </label>
                  <div>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter lead time in days"
                          id="number-forms-lead_time"
                          {...register("lead_time", {
                            required: "Lead Time is required",
                            validate: {
                              positive: (value) =>
                                value > 0 ||
                                "Only positive numbers are allowed",
                              integer: (value) =>
                                Number.isInteger(parseFloat(value)) ||
                                "Only integers are allowed",
                            },
                          })}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            in days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.lead_time && (
                    <span className="fs-8 text-danger">
                      {errors.lead_time.message}
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Procurement Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={procurementType?.map((api) => {
                          return {
                            value: api.name,
                            label: api.name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-procurement_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="procurement_type"
                    control={control}
                  />
                  {errors.procurement_type && (
                    <span className="fs-8 text-danger">
                      Procurement Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Material Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={materialType?.map((api) => {
                          return {
                            label: api.name,
                            value: api.name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-material_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="material_type"
                    control={control}
                  />
                  {errors.material_type && (
                    <span className="fs-8 text-danger">
                      Material Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Material Sub Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={subMaterialType?.map((api) => {
                          return {
                            label: api.name,
                            value: api.name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-material_subtype"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="material_subtype"
                    control={control}
                  />
                  {errors.material_subtype && (
                    <span className="fs-8 text-danger">
                      Material Sub Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Category Type
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={category?.map((api) => {
                          return {
                            label: api.name,
                            value: api.name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-category"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Stock Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter stock quantity"
                    id="number-forms-stock_quantity"
                    {...register("stock_quantity", {
                      required: "Stock Quantity is required",
                      validate: {
                        positive: (value) =>
                          value > 0 || "Only positive numbers are allowed",
                        integer: (value) =>
                          Number.isInteger(parseFloat(value)) ||
                          "Only integers are allowed",
                      },
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.stock_quantity && (
                    <span className="fs-8 text-danger">
                      {errors.stock_quantity.message}
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Unit of Meaurement
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={unitMeaurement?.map((api) => {
                          return { label: api.name, value: api.name };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-uom"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="uom"
                    control={control}
                  />
                  {errors.uom && (
                    <span className="fs-8 text-danger">
                      Unit of Meaurement is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-product-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-product"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProduct;
