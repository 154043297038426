import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditBO = () => {
  const userID = secureLocalStorage.getItem("userID")
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // select dropdown
  const [item, setItem] = useState([]);
  const [bom, setBom] = useState([]);
  const [workOrder, setWorkOrder] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true,
    refetchInterval : delay_time  // after every 2 seconds the generic api recalls
  });

  const { useUpdate } = useAPIQuery({
    key: "bo",
    endpoint: "/api/build-order/",
  });

  const handleUpdateBO = (data) => {
    Swal.fire({
      title: "BO Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorBO = (data) => {
    Swal.fire({
      title: "Error Updating BO",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateBO,
    onError: handleErrorBO,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var bo = {
      item_id: data.item_id.value,
      work_order_req_quantity: data.work_order_req_quantity,
      bom_id: data.bom_id.value,
      work_order_id: data.work_order_id.value,
      allotted_stock: data.allotted_stock,
      is_stock_allotted: true,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: bo,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editBO = location.state.editpost;
    // defaultValues is a react form hook.
    if (editBO) {
      var defaultValues = {};
      defaultValues.work_order_req_quantity = editBO.work_order_req_quantity;
      defaultValues.allotted_stock = editBO.allotted_stock;

      const getItem = {
        value: editBO.item?.id,
        label: editBO.item?.item_name,
      };
      defaultValues.item_id = getItem;

      const getBO = {
        value: editBO.bom?.id,
        label: editBO.bom?.bom_code,
      };
      defaultValues.bom_id = getBO;

      const getWorkOrder = {
        value: editBO.work_order?.id,
        label: editBO.work_order?.work_order_code,
      };
      defaultValues.work_order_id = getWorkOrder;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setItem(data.item.filter((api) => api.category !== "FG"));
      setBom(data.bom);
      setWorkOrder(data.work_order);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit BO </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((api) => {
                          return { label: api.item_name, value: api.id };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">Item is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work Order Request Quantity
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter work order request quantity"
                    id="email-forms-work_order_req_quantity"
                    {...register("work_order_req_quantity", { required: true })}
                  />
                  {errors.work_order_req_quantity && (
                    <span className="fs-8 text-danger">
                      Work Order Request Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    BOM<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={bom?.map((api) => {
                          return { value: api.id, label: api.bom_code };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-bom_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="bom_id"
                    control={control}
                  />
                  {errors.bom_id && (
                    <span className="fs-8 text-danger">BOM is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work Order<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={workOrder?.map((api) => {
                          return { value: api.id, label: api.wo_order };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-work_order_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="work_order_id"
                    control={control}
                  />
                  {errors.work_order_id && (
                    <span className="fs-8 text-danger">
                      Work Order is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Allotted Stock
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter allotted stock"
                    id="email-forms-allotted-stock"
                    {...register("allotted_stock", { required: true })}
                  />
                  {errors.allotted_stock && (
                    <span className="fs-8 text-danger">
                      Allotted Stock is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-bo-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-bo"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBO;
