import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import Rejection from "./Rejection";

const SalesOrder = () => {
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const columns = [
    {
      accessor: "so_number",
      header: "Sales Order number",
    },
    {
      accessor: "client",
      header: "Client Name",
      cell: (row) => (
        <>
          {row?.client?.user?.first_name + " " + row?.client?.user?.last_name}
        </>
      ),
    },
    {
      accessor: "product",
      header: "Product Name",
      cell: (row) => <>{row?.product?.item_name}</>,
    },
    {
      accessor: "product",
      header: "Product ID",
      cell: (row) => <>{row?.product?.item_code}</>,
    },
    {
      accessor: "target_date",
      header: "Target Date",
    },
    {
      accessor: "dispatch_location",
      header: "Dispatch Location",
    },
    {
      accessor: "quantity",
      header: "Quantity",
    },
    privileges.includes("work_order_split") && {
      accessor: "button",
      header: "Work Order",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => onFGoodsHandler(row)}
          >
            Work
          </button>
        </>
      ),
    },
    privileges.includes("sales_order_approval") && {
      accessor: "approval_button",
      header: "Approve Sales Order",
      cell: (row) => (
        <>
          {row?.activity_status === 1 ? (
            <span className="badge badge-dot bg-success">Approved</span>
          ) : row?.activity_status === 0 ? (
            <span className="badge badge-dot bg-danger">Reject</span>
          ) : row?.activity_status === 2 ? (
            <>
              <div className="d-flex justify-content-evenly">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => handleApproveSalesOrder(row, true)}
                >
                  <em className="icon ni ni-check"></em>
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => handleApproveSalesOrder(row, false)}
                >
                  <em className="icon ni ni-cross"></em>
                </button>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      accessor: "action_button",
      header: "Action",
      cell: (row) => (
        <>
          {row.activity_status !== 1 ? (
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-trigger"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <em className="icon ni ni-more-v"></em>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <ul className="link-list-plain">
                  {privileges.includes("sales_order_update") && (
                    <li>
                      <a
                        className="text-warning"
                        onClick={() => handleEditRow(row)}
                        style={{ cursor: "pointer" }}
                      >
                        Edit
                      </a>
                    </li>
                  )}
                  {privileges.includes("sales_order_delete") && (
                    <li>
                      <a
                        className="text-danger"
                        onClick={() => handleDeleteRow(row)}
                        style={{ cursor: "pointer" }}
                      >
                        Delete
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ) : (
            "No Actions"
          )}
        </>
      ),
    },
  ].filter(Boolean);

  const navigate = useNavigate();
  const [salesOrderEntries, setSalesOrderEntries] = useState(5);
  const [salesOrderPageIndex, setSalesOrderPageIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [rejectRowDetails, setRejectRowDetails] = useState(null);

  const onFGoodsHandler = (row) => {
    navigate("/sales-work-order", {
      state: { id: row?.id },
    });
  };

  const { useFetch, useUpdate: updateSalesOrder } = useAPIQuery({
    key: "sales_order",
    endpoint: "/api/sales-order/",
  });

  const { data, isLoading, isFetching, isError, error } = useFetch({
    params: {
      entries: salesOrderEntries,
      page_no: salesOrderPageIndex + 1,
      event: search ? search : undefined,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
  });

  const { mutate: approveSalesOrder } = updateSalesOrder();

  const handleApproveSalesOrder = (row, approve_status) => {
    if (approve_status) {
      Swal.fire({
        text: `Would you like to approve Sales Order?`,
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No, cancel!`,
      }).then((result) => {
        if (result.isConfirmed) {
          approveSalesOrder({
            id: row?.id,
            data: { activity_status: 1, updated_by: userID },
          });
        }
      });
    } else {
      Swal.fire({
        text: `Would you like to reject the Sales Order?`,
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No, cancel!`,
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("reject-sales-order").click();
          setRejectRowDetails(row);
          // approveSalesOrder({
          //   id: row?.id + "/",
          //   data: { activity_status: 0 },
          // });
        }
      });
    }
  };

  const { useDelete } = useAPIQuery({
    key: "sales_order",
    endpoint: "/api/sales-order/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setSalesOrderPageIndex(value.pageIndex);
    setSalesOrderEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-sales-order", { state: { editpost: row } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  console.log(isError);
  console.log(error);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Sales Order</h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("sales_order_create") && (
            <div className="nk-block-head-content">
              <NavLink
                to="/create-sales-order"
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-create-sales-order"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to="/create-sales-order"
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-create-sales-order"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Sales Order</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <div>Loading...</div>
      ) : !isError ? (
        <Table
          data={data ? data?.results?.data : []}
          columns={columns}
          pagination
          pageIndex={salesOrderPageIndex}
          pageSize={salesOrderEntries}
          rowCount={data ? data?.count : 0}
          isLoading={isFetching}
          globalSearch
          editRows={false}
          deleteRows={false}
          filterModal={false}
          selectRows={false}
          onGlobalFilterChange={handleGlobalFilterChange}
          onPaginationChange={handlePagination}
        />
      ) : null}

      <Rejection rejectRowDetails={rejectRowDetails} />

      <button
        id="reject-sales-order"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#reject_sales_order"
        style={{ display: "none" }}
      >
        Open Modal 2
      </button>
    </>
  );
};

export default SalesOrder;
