import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import Login from "./Component/Authentication/Login";
import Base from "./Component/Base/Base";
import { GlobalContext } from "./Component/Context/GlobalContext";

const App = () => {
  // Review
  // This authentication states can be directly imported from library
  const { isLoggedIn, setLoginStatus } = useContext(GlobalContext);
  // secureLocalStorage.setItem("privileges", [
  //   "sales_order_retrieve",
  //   "sales_order_create",
  //   "sales_order_update",
  //   "sales_order_delete",
  //   "work_order_split",
  //   "sales_order_approval",
  //   "daily_plan",
  //   "master",
  //   "client_retrieve",
  //   "client_create",
  //   "client_update",
  //   "client_delete",
  //   "bom_retrieve",
  //   "bom_create",
  //   "bom_update",
  //   "bom_delete",
  //   "item_retrieve",
  //   "item_create",
  //   "item_update",
  //   "item_delete",
  //   "work_order_retrieve",
  //   "work_order_create",
  //   "work_order_update",
  //   "work_order_delete",
  //   "stock_transcation_retrieve",
  //   "stock_transcation_create",
  //   "stock_transcation_update",
  //   "stock_transcation_delete",
  //   "bo_retrieve",
  //   "bo_create",
  //   "bo_update",
  //   "bo_delete",
  //   "setting",
  //   "privilege_retrieve",
  //   "privilege_create",
  //   "privilege_update",
  //   "privilege_delete",
  //   "role_retrieve",
  //   "role_create",
  //   "role_update",
  //   "role_delete",
  //   "type_master_retrieve",
  //   "type_master_create",
  //   "type_master_update",
  //   "type_master_delete",
  //   "type_master_category_retrieve",
  //   "type_master_category_create",
  //   "type_master_category_update",
  //   "type_master_category_delete",
  //   "check_bom",
  //   "supplier_retrieve",
  //   "supplier_create",
  //   "supplier_update",
  //   "supplier_delete",
  //   "machine_retrieve",
  //   "machine_create",
  //   "machine_update",
  //   "machine_delete",
  //   "quality_rules_retrieve",
  //   "quality_rules_create",
  //   "quality_rules_update",
  //   "quality_rules_delete",
  //   "quality_parameter_retrieve",
  //   "quality_parameter_create",
  //   "quality_parameter_update",
  //   "quality_parameter_delete",
  //   "quality_rule_association_retrieve",
  //   "quality_rule_association_create",
  //   "quality_rule_association_update",
  //   "quality_rule_association_delete",
  //   "quality_check_retrieve",
  //   "quality_check_create",
  //   "quality_check_update",
  //   "quality_check_delete",
  //   "operator_retrieve",
  //   "operator_create",
  //   "operator_update",
  //   "operator_delete",
  //   "daily_production_retrieve",
  //   "daily_production_create",
  //   "daily_production_update",
  //   "daily_production_delete",
  //   "machine_status_retrieve",
  //   "machine_status_create",
  //   "machine_status_update",
  //   "machine_status_delete",
  // ]);

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem("isLoggedIn");
    if (userStatus === "USER_LOGGED_IN") {
      setLoginStatus(true);
    } else if (userStatus === "USER_LOGGED_OUT") {
      setLoginStatus(false);
    }
  }, []);

  return (
    <>
      {isLoggedIn && <Base />}
      {!isLoggedIn && <Login />}
    </>
  );
};

export default App;
