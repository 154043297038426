import { useGlobalAPIQueryContext } from "@dev2_techwalnut/tw-api-query-hook";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

export const GlobalContext = createContext(null);

function GlobalContextProvider({ children }) {
  // Review
  // For cityLocation state, library could have been used
  // Review - Vaishnavi - 22/01/2025 - Generic API should be in Context.
  const [cityLocation, setCityLocation] = useState([]);
  const navigate = useNavigate();

  const {
    isLoggedIn,
    isLoading: globalLoading,
    error: globalError,
    setLoginStatus,
  } = useGlobalAPIQueryContext();

  const loginHandler = (data) => {
    setLoginStatus(true, data.access, data.refresh);
    secureLocalStorage.setItem("isLoggedIn", "USER_LOGGED_IN");
    secureLocalStorage.setItem("userID", data.id);
    secureLocalStorage.setItem("username", data.username);
    secureLocalStorage.setItem("firstName", data.first_name);
    secureLocalStorage.setItem("lastName", data.last_name);
    secureLocalStorage.setItem("email", data.email);
    secureLocalStorage.setItem("privileges", data.privileges);
    console.log(data.privileges)
    // secureLocalStorage.setItem("accessToken", data.access_token);
    // secureLocalStorage.setItem("refreshToken", data.refresh_token);
  };

  const logoutHandler = () => {
    setLoginStatus(false);
    secureLocalStorage.clear();
    navigate("/");
  };

  const getCitiesData = async () => {
    let data = JSON.stringify({
      country: "India",
      state: "Gujarat",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://countriesnow.space/api/v0.1/countries/state/cities",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCityLocation(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCitiesData();
  }, []);

  return (
    <div>
      <GlobalContext.Provider
        value={{
          loginHandler,
          logoutHandler,
          isLoggedIn,
          cityLocation,
          setLoginStatus,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </div>
  );
}

export default GlobalContextProvider;
