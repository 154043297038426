import { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import moment from "moment";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const useDailyPlanCalendar = (props) => {
    const [woEvents, setWoEvents] = useState([]);
    const [backupEvents, setBackupEvents] = useState([]);
    const [currentSlot, setCurrentSlot] = useState(null);
    const [currentWO, setCurrentWO] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);
    const userID = secureLocalStorage.getItem("userID");

    const { useFetch: useFetchMachineInfo, useCreate: useCreateDailyPlans } =
        useAPIQuery({
            key: `machine-info-${props?.machine_id}`,
            endpoint: "/api/daily-plan-machine/",
        });
    const { useDelete: useDeleteDailyPlan } = useAPIQuery({
        key: "daily-plan",
        endpoint: "/api/daily-plan/",
    });
    const {
        data: machineData,
        isLoading: loadingMachineInfoData,
        isError: errorLoadingMachineInfo,
        isFetching: fetchingMachineData,
        refetch: refetchMachineData,
    } = useFetchMachineInfo({
        params: {
            machine_id: props.machine_id,
            date: moment(currentDate ?? new Date()).format("yyyy-MM"),
        },
        staleTime: 500000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 3,
        keepPlaceholder: true,
        enabled: props.machine_id === props.activeAccordion,
    });
    const { mutate: deleteDailyPlan } = useDeleteDailyPlan({
        onSuccess: (data) => {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: `Deleted Successfully`,
            }).then((result) => {
                refetchMachineData();
                setBackupEvents(woEvents);
            });
        },
        onError: (error) => {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: `Unable to delete the daily plan`,
            }).then((result) => {
                refetchMachineData();
                setWoEvents(backupEvents);
            });
        },
    });
    const { mutate: createDailyPlan } = useCreateDailyPlans({
        onSuccess: (data) => {
            console.log(data);
            Swal.fire({
                title: "Success!",
                text: data?.message,
                icon: "success",
            }).then((result) => {
                setBackupEvents(woEvents);
            });
        },
        onError: (error) => {
            Swal.fire({
                title: "Error!",
                text: error?.message,
                icon: "error",
            }).then((result) => {
                setWoEvents(backupEvents);
            });
        },
    });
    const { useFetch: useFetchWorkOrders } = useAPIQuery({
        key: "generic",
        endpoint: "/api/generic/",
    });
    const {
        data: workOrdersData,
        isLoading: loadingWorkOrdersData,
        isError: errorLoadingWorkOrders,
    } = useFetchWorkOrders({
        staleTime: 60 * 60 * 5,
        refetchOnMount: false,
        retry: 3,
        refetchOnWindowFocus: false,
        keepPlaceholder: true,
    });
    const machine = useMemo(() => {
        if (machineData) {
            const res = machineData?.data[0];
            const data = [
                ...res.daily_plan.map((os) => ({
                    id: os.id,
                    title: `${os.wo.work_order_code} - ${os.target}`,
                    wo: os.wo.id,
                    start: new Date(os.date),
                    end: new Date(os.date),
                    amount: os.target,
                })),
            ];
            setWoEvents(data);
            setBackupEvents(data);
            return res;
        }
    }, [machineData]);

    const workOrders = useMemo(() => {
        if (workOrdersData) {
            return workOrdersData?.work_order;
        }
    }, [workOrdersData]);

    const handleSelectSlot = (slotInfo) => {
        if (!machine) return;
        if (
            new Date(slotInfo.slots[0]).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0)
        )
            return;

        // Review -  Vaishnavi - 22/01/25 - all the conditions to be handle to avoid page crash
        // find all the work orders with same date as selected date
        const slots = woEvents.filter((os) => {
            return (
                new Date(os.start).setHours(0, 0, 0, 0) ===
                new Date(slotInfo.slots[0]).setHours(0, 0, 0, 0)
            );
        });

        // check if the total amount of work orders is greater than machine capacity
        const totalByAmount = slots.reduce((sum, slot) => sum + slot.amount, 0);

        // if the total amount is greater than machine capacity, return
        if (totalByAmount >= machine?.capacity) return;

        setCurrentSlot(slotInfo.slots[0]);

        console.log(machine.id);

        // open modal
        const button = document.createElement("button");
        button.className = "d-none";
        button.id = "open-modal";
        button.setAttribute("data-bs-toggle", "modal");
        button.setAttribute(
            "data-bs-target",
            `#add-wo-modal-${props.machine_id}`
        );
        document.body.appendChild(button);
        button.click();
        button.remove();
    };

    const handleSelectEvent = (event) => {
        setCurrentWO(event);
        const button = document.createElement("button");
        button.className = "d-none";
        button.id = "open-edit-modal";
        button.setAttribute("data-bs-toggle", "modal");
        button.setAttribute(
            "data-bs-target",
            `#edit-wo-modal-${props.machine_id}`
        );
        document.body.appendChild(button);
        button.click();
        button.remove();
    };

    const validateSlot = (slot) => {
        if (!machine) return;
        const events = woEvents.filter(
            (event) =>
                new Date(event.start).setHours(0, 0, 0, 0) ===
                new Date(slot).setHours(0, 0, 0, 0)
        );
        const totalByAmount = events.reduce(
            (sum, event) => sum + event.amount,
            0
        );
        if (totalByAmount >= machine.capacity) return false;
        return {
            available: machine.capacity - totalByAmount,
        };
    };

    const handleCreateDailyPlan = (data) => {
        createDailyPlan({
            data: {
                bulk_upload: data?.map((d) => ({
                    id: null,
                    date: moment(d?.start).format("yyyy-MM-DD"),
                    qty_to_be_produced: d?.amount,
                    wo_id: d?.wo,
                    machine_id: props?.machine_id,
                    item_id: props?.wip?.wip,
                })),
                created_by: userID,
                updated_by: userID,
            },
        });
    };

    const handleUpdateDailyPlan = (data, additionalWOs) => {
        createDailyPlan({
            data: {
                bulk_upload: [
                    ...additionalWOs?.map((wo) => ({
                        id: null,
                        date: moment(wo?.start).format("yyyy-MM-DD"),
                        qty_to_be_produced: wo?.amount,
                        wo_id: wo?.wo,
                        machine_id: props?.machine_id,
                        item_id: props?.wip?.wip,
                    })),
                    {
                        id: data?.id,
                        date: moment(data?.start).format("yyyy-MM-DD"),
                        qty_to_be_produced: data?.amount,
                        wo_id: data?.wo,
                        machine_id: props?.machine_id,
                        item_id: props?.wip?.wip,
                    },
                ],
                created_by: userID,
                updated_by: userID,
            },
        });
    };

    const addNewDailyPlans = (wo, fromDate, toAssign) => {
        const workOrder = workOrders?.find((w) => w.id === wo?.id);
        if (!workOrder) return;

        let dateTemp = new Date(fromDate);
        dateTemp.setHours(0, 0, 0, 0);

        const beforeDate = new Date(workOrder.target_date);
        beforeDate.setHours(0, 0, 0, 0);

        let newEvents = [];
        let assignedAmount = toAssign;

        console.log(dateTemp <= beforeDate);
        console.log(assignedAmount);

        while (dateTemp <= beforeDate && assignedAmount > 0) {
            console.log("hello");
            const availability = validateSlot(dateTemp);
            if (availability) {
                if (assignedAmount - availability.available > 0) {
                    const newProduce = availability.available;
                    newEvents.push({
                        id: uuid(),
                        title: `${workOrder.work_order_code} - ${newProduce}`,
                        wo: wo?.id,
                        start: new Date(dateTemp),
                        end: new Date(dateTemp),
                        amount: newProduce,
                    });
                    assignedAmount -= availability.available;
                } else {
                    const newProduce = assignedAmount;
                    newEvents.push({
                        id: uuid(),
                        title: `${workOrder.work_order_code} - ${newProduce}`,
                        wo: wo?.id,
                        start: new Date(dateTemp),
                        end: new Date(dateTemp),
                        amount: newProduce,
                    });
                    assignedAmount = 0;
                }
            }
            const nextDate = new Date(dateTemp);
            nextDate.setDate(nextDate.getDate() + 1);
            dateTemp = nextDate;
        }
        console.log(newEvents);
        return newEvents ?? [];
    };

    const handleWOSelect = (wo) => {
        const workOrder = workOrders?.find((w) => w.id === wo.value);
        console.log(workOrder);
        if (!workOrder) return;

        let dateTemp = new Date(currentSlot);
        dateTemp.setHours(0, 0, 0, 0);

        const beforeDate = new Date(workOrder.target_date);
        beforeDate.setHours(0, 0, 0, 0);

        let newEvents = [];
        let assignedAmount =
            workOrder.target_quantity - workOrder.allotted_quantity;

        while (dateTemp <= beforeDate && assignedAmount > 0) {
            const availability = validateSlot(dateTemp);
            if (availability) {
                if (assignedAmount - availability.available > 0) {
                    const newProduce = availability.available;
                    newEvents.push({
                        id: uuid(),
                        title: `${workOrder.work_order_code} - ${newProduce}`,
                        wo: wo.value,
                        start: new Date(dateTemp),
                        end: new Date(dateTemp),
                        amount: newProduce,
                    });
                    assignedAmount -= availability.available;
                } else {
                    const newProduce = assignedAmount;
                    newEvents.push({
                        id: uuid(),
                        title: `${workOrder.work_order_code} - ${newProduce}`,
                        wo: wo.value,
                        start: new Date(dateTemp),
                        end: new Date(dateTemp),
                        amount: newProduce,
                    });
                    assignedAmount = 0;
                }
            }
            const nextDate = new Date(dateTemp);
            nextDate.setDate(nextDate.getDate() + 1);
            dateTemp = nextDate;
        }

        console.log(newEvents);
        handleCreateDailyPlan(newEvents);
        setWoEvents((prev) => [...prev, ...newEvents]);
    };

    const handleWOEdit = (date, quantity) => {
        const events = woEvents.filter((event) => {
            const eventDate = new Date(event.start).setHours(0, 0, 0, 0);
            const inputDate = new Date(date).setHours(0, 0, 0, 0);
            return eventDate === inputDate;
        });

        const workOrder = workOrders?.find((w) => w.id === currentWO?.wo);

        const toAllot = events.reduce(
            (accumulator, current) => accumulator + current.amount,
            0
        );
        console.log(events);

        let additionalWOs = [];

        if (events.length === 1) {
            if (events.length > 0) {
                console.log(new Date(events[0].start));
                console.log(new Date(date));
                if (
                    new Date(currentWO?.start).setHours(0, 0, 0, 0) !==
                    new Date(date).setHours(0, 0, 0, 0)
                ) {
                    if (machine?.capacity - toAllot <= 0) {
                        Swal.fire({
                            icon: "error",
                            title: "Not allowed",
                            text: `Cannot assign daily plan on a date where capacity is not available`,
                        });
                        return;
                    }
                    const newDate = new Date(date);
                    newDate.setDate(newDate.getDate() + 1);
                    additionalWOs = addNewDailyPlans(
                        workOrder,
                        newDate,
                        quantity - (machine?.capacity - toAllot)
                    );
                    console.log(
                        addNewDailyPlans(
                            workOrder,
                            newDate,
                            quantity - (machine?.capacity - toAllot)
                        )
                    );
                }
            }
        }

        if (workOrder?.target_date) {
            const beforeDate = new Date(workOrder.target_date).setHours(
                0,
                0,
                0,
                0
            );
            const newDate = new Date(date).setHours(0, 0, 0, 0);
            if (beforeDate < newDate) {
                Swal.fire({
                    icon: "error",
                    title: "Not allowed",
                    text: `Cannot assign daily plan after target date i.e ${moment(
                        beforeDate
                    ).format("yyyy-MM-DD")}`,
                });
                return;
            }
        }

        if (
            new Date(date).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0)
        ) {
            Swal.fire({
                icon: "error",
                title: "Not allowed",
                text: `Cannot assign daily plan before current date i.e ${moment(
                    new Date()
                ).format("yyyy-MM-DD")}`,
            });
            return;
        }

        let updatedDP = null;

        const updatedEvents = woEvents.map((event) => {
            if (event.id === currentWO?.id) {
                console.log(currentWO);
                if (quantity) {
                    updatedDP = {
                        ...currentWO,
                        title: `${workOrder?.work_order_code} - ${
                            machine?.capacity - toAllot
                        }`,
                        start: new Date(date),
                        end: new Date(date),
                        amount: machine?.capacity - toAllot,
                    };
                    return updatedDP;
                }
                updatedDP = {
                    ...currentWO,
                    start: new Date(date),
                    end: new Date(date),
                };
                return updatedDP;
            }
            return event;
        });
        const updatedNewEvents = updatedEvents.filter((event) => {
            const eventDate = new Date(event.start).setHours(0, 0, 0, 0);
            const inputDate = new Date(date).setHours(0, 0, 0, 0);
            return eventDate === inputDate;
        });
        if (machine) {
            const checkQuentity = updatedNewEvents.reduce(
                (accumulator, current) => accumulator + current.amount,
                0
            );
            console.log(checkQuentity);
            if (checkQuentity > machine?.capacity) {
                Swal.fire({
                    icon: "error",
                    title: "Not allowed",
                    text: `Cannot assign quantity more than machine capacity i.e ${machine?.capacity}`,
                });
                return;
            }
        }
        if (additionalWOs === undefined) {
            Swal.fire({
                icon: "error",
                title: "Not allowed",
                text: `Unable to find workorder`,
            });
            return;
        }
        if (updatedDP) {
            handleUpdateDailyPlan(updatedDP, additionalWOs);
        }
        setWoEvents([...updatedEvents, ...additionalWOs]);
    };

    const handleWORemove = () => {
        const updatedEvents = woEvents.filter((event) => {
            if (event.id === currentWO?.id) {
                return false;
            }
            return true;
        });
        console.log(currentWO);
        Swal.fire({
            text: "Are you sure? You will not be able to recover this data!",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No, cancel!`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDailyPlan({
                    id: currentWO?.id,
                });
                setWoEvents(updatedEvents);
            }
        });
    };

    return {
        machine,
        woEvents,
        handleSelectSlot,
        handleSelectEvent,
        currentSlot,
        currentWO,
        handleWOSelect,
        handleWOEdit,
        handleWORemove,
        setCurrentDate,
        loadingMachineInfoData,
        loadingWorkOrdersData,
        fetchingMachineData,
        errorLoadingWorkOrders,
        errorLoadingMachineInfo,
    };
};

export default useDailyPlanCalendar;
