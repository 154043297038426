import React from "react";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";

const Rejection = ({ rejectRowDetails }) => {
  const userID = secureLocalStorage.getItem("userID");
  console.log(rejectRowDetails);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const { useUpdate: updateSalesOrder } = useAPIQuery({
    key: "sales_order",
    endpoint: "/api/sales-order/",
  });

  const { mutate: approveSalesOrder } = updateSalesOrder();

  const onSubmit = (data) => {
    approveSalesOrder({
      id: rejectRowDetails?.id,
      data: { activity_status: 0, comment: data.comments, updated_by : userID },
    });
    reset()
    document.getElementById('close-reject-modal').click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="reject_sales_order"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="exampleModalLabel">
                Add the Reason of Rejecting the Sales Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="modal-body">
                <label className="form-label">
                  Reason (Comments)<span className="fs-8 text-danger"> *</span>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Enter the reason for rejecting the sales order"
                  id="textarea-forms-comments"
                  {...register("comments", { required: true })}
                />
                {errors.comments && (
                  <span className="fs-8 text-danger">
                    Reason (Comments) is required
                  </span>
                )}
              </div>
              <div className="modal-footer">
                <button
                  id="close-reject-modal"
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rejection;
