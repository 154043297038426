import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const CreateDailyProductionReport = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();

  // select dropdown
  const [workOrder, setWorkOrder] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useCreate } = useAPIQuery({
    key: "daily_production_report",
    endpoint: "/api/daily-production-report/",
  });

  const handleCreateDailyProduction = (data) => {
    Swal.fire({
      title: "Daily Production Report Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorDailyProduction = (data) => {
    Swal.fire({
      title: "Error Adding Daily Production Report",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateDailyProduction,
    onError: handleErrorDailyProduction,
    retry : 2
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    const formattedDate = data.target_date ? format(new Date(data.target_date), "yyyy-MM-dd") : null;
    var daily_report = {
      target_date: formattedDate,
      work_order_id: data.work_order_id.value,
      production_qty: data.production_qty,
      created_by : userID
    };

    mutate({
      data: daily_report,
    });
  };

  useEffect(() => {
    if (data) {
      setWorkOrder(data.work_order);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">
              Create Daily Production Report
            </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work Order <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={workOrder?.map((api) => {
                          return {
                            value: api?.id,
                            label: api?.work_order_code,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-work-order-id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="work_order_id"
                    control={control}
                  />
                  {errors.work_order_id && (
                    <span className="fs-8 text-danger">
                      Work Order Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Target Date
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <div>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <Controller
                          render={({ field }) => (
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Target date"
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              minDate={new Date()} // Can't select past dates
                              maxDate={new Date("2030-12-31")} // Set max date
                            />
                          )}
                          defaultValue=""
                          rules={{ required: true }}
                          name="target_date"
                          control={control}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            <em class="icon ni ni-calendar-check-fill"></em>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.target_date && (
                    <span className="fs-8 text-danger">
                      Target Date is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Production Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter production quantity"
                    id="text-forms-production-quantity"
                    {...register("production_qty", { required: true })}
                  />
                  {errors.production_qty && (
                    <span className="fs-8 text-danger">
                      Production Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-production-qty-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-production-qty"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDailyProductionReport;