import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "machine_status",
    header: "Machine status",
  },
  {
    accessor: "machine_id",
    header: "Machine Department",
    cell: (row) => <>{row?.machine?.department}</>,
  },
  {
    accessor: "status_date",
    header: "Status Date",
  },
  {
    accessor: "machine_id",
    header: "Machine WIP",
    cell: (row) => <>{row?.machine?.wip}</>,
  },
];

const MachineStatus = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [machineStatusEntries, setMachineStatusEntries] = useState(5);
  const [machineStatusPageIndex, setMachineStatusPageIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { useFetch } = useAPIQuery({
    key: "machine_status",
    endpoint: "/api/machine-status/",
  });

  const { data, isLoading } = useFetch({
    params: {
      entries: machineStatusEntries,
      page_no: machineStatusPageIndex + 1,
      event: search ? search : undefined,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime : 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true
  });

  const { useDelete } = useAPIQuery({
    key: "machine_status",
    endpoint: "/api/machine-status/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setMachineStatusPageIndex(value.pageIndex);
    setMachineStatusEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-machine-status", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Machine Status</h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("machine_status_create") && (
            <div className="nk-block-head-content">
              <NavLink
                to="/create-machine-status"
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-create-machine-status"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to="/create-machine-status"
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-create-machine-status"
              >
                <em className="icon ni ni-plus"></em>
                <span>Create Machine Status</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <Table
        data={data ? data?.results.data : []}
        columns={columns}
        pagination
        pageIndex={machineStatusPageIndex}
        pageSize={machineStatusEntries}
        rowCount={data ? data.count : 0}
        globalSearch
        editRows={privileges.includes("machine_status_update") ? true : false}
        deleteRows={privileges.includes("machine_status_delete") ? true : false}
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default MachineStatus;
