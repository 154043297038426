import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";

const AssignWoModal = ({ handleWOSelect, machine_id }) => {
    const { handleSubmit, control } = useForm();

    const { useFetch: useFetchWorkOrders } = useAPIQuery({
        key: "generic",
        endpoint: "/api/generic/",
    });
    const { data: workOrdersData, isLoading: loadingWorkOrdersData } =
        useFetchWorkOrders({
            staleTime: 60 * 60 * 5,
            refetchOnMount: false,
            retry: 3,
            refetchOnWindowFocus: false,
            keepPlaceholder: true,
        });

    const onSubmit = (data) => {
        handleWOSelect(data.wo);
    };
    return (
        <div
            className="modal fade m-0 p-0"
            tabIndex={-1}
            id={`add-wo-modal-${machine_id}`}
            aria-modal
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <button className="close" data-bs-dismiss="modal">
                        <em
                            className="icon ni ni-cross"
                            aria-hidden="true"
                        ></em>
                    </button>
                    <div className="modal-header">
                        <h5 className="modal-title">Add Work Order</h5>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="wo">Work Order</label>
                                        <Controller
                                            name="wo"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={
                                                        workOrdersData
                                                            ? [
                                                                  ...workOrdersData?.work_order?.map(
                                                                      (wo) => ({
                                                                          ...wo,
                                                                          value: wo?.id,
                                                                          label: `${
                                                                              wo?.work_order_code
                                                                          }-${
                                                                              wo?.target_quantity -
                                                                              wo?.allotted_quantity
                                                                          }`,
                                                                      })
                                                                  ),
                                                              ].filter(
                                                                  (wo) =>
                                                                      wo?.target_quantity -
                                                                          wo?.allotted_quantity >
                                                                      0
                                                              )
                                                            : []
                                                    }
                                                    placeholder="Select a work order"
                                                    isLoading={
                                                        loadingWorkOrdersData
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer bg-light">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AssignWoModal;
