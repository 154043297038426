import React from "react";
import { Route, Routes } from "react-router-dom";
import Role from "../Pages/Setting/Roles/Role";
import CreateRole from "../Pages/Setting/Roles/CreateRole";
import EditRole from "../Pages/Setting/Roles/EditRole";
import Privilege from "../Pages/Setting/Privilege/Privilege";
import CreatePrivilege from "../Pages/Setting/Privilege/CreatePrivilege";
import EditPrivilege from "../Pages/Setting/Privilege/EditPrivilege";
import TypeMaster from "../Pages/Setting/TypeMaster/TypeMaster";
import CreateTypeMaster from "../Pages/Setting/TypeMaster/CreateTypeMaster";
import EditTypeMaster from "../Pages/Setting/TypeMaster/EditTypeMaster";
import TypeMasterCategory from "../Pages/Setting/TypeMasterCategory/TypeMasterCategory";
import CreateTypeMasterCategory from "../Pages/Setting/TypeMasterCategory/CreateTypeMasterCategory";
import EditTypeMasterCategory from "../Pages/Setting/TypeMasterCategory/EditTypeMasterCategory";
import SalesOrder from "../Pages/SalesOrder/SalesOrder";
import CreateSalesOrder from "../Pages/SalesOrder/CreateSalesOrder";
import EditSalesOrder from "../Pages/SalesOrder/EditSalesOrder";
import Client from "../Pages/Client/Client";
import CreateClient from "../Pages/Client/CreateClient";
import EditClient from "../Pages/Client/EditClient";
import BOM from "../Pages/BOM/BOM";
import CreateBOM from "../Pages/BOM/CreateBOM";
import EditBOM from "../Pages/BOM/EditBOM";
import Product from "../Pages/Product/Product";
import CreateProduct from "../Pages/Product/CreateProduct";
import EditProduct from "../Pages/Product/EditProduct";
import WorkOrder from "../Pages/WorkOrder/WorkOrder";
import CreateWorkOrder from "../Pages/WorkOrder/CreateWorkOrder";
import EditWorkOrder from "../Pages/WorkOrder/EditWorkOrder";
import BO from "../Pages/BO/BO";
import EditBO from "../Pages/BO/EditBO";
import CreateBO from "../Pages/BO/CreateBO";
import StockTransaction from "../Pages/StockTransaction/StockTransaction";
import CreateStockTransaction from "../Pages/StockTransaction/CreateStockTransaction";
import EditStockTransaction from "../Pages/StockTransaction/EditStockTransaction";
import Error from "../ErrorPages/Error";
import NotFound from "../ErrorPages/NotFound";
import secureLocalStorage from "react-secure-storage";
import WorkOrderSplit from "../Pages/WorkOrder/WorkOrderSplit";
import DailyPlan from "../Pages/DailyPlan/DailyPlan";
import Supplier from "../Pages/Supplier/Supplier";
import CreateSupplier from "../Pages/Supplier/CreateSupplier";
import EditSupplier from "../Pages/Supplier/EditSupplier";
import Machine from "../Pages/Machine/Machine";
import CreateMachine from "../Pages/Machine/CreateMachine";
import EditMachine from "../Pages/Machine/EditMachine";
import ClientItemAssociation from "../Pages/ClientItemAssociation/ClientItemAssociation";
import CreateClientItemAssociation from "../Pages/ClientItemAssociation/CreateClientItemAssociation";
import EditClientItemAssociation from "../Pages/ClientItemAssociation/EditClientItemAssociation";
import SupplierItemAssociation from "../Pages/SupplierItemAssociation/SupplierItemAssociation";
import CreateSupplierItemAssociation from "../Pages/SupplierItemAssociation/CreateSupplierItemAssociation";
import EditSupplierItemAssociation from "../Pages/SupplierItemAssociation/EditSupplierItemAssociation";
import QualityRules from "../Pages/QualityRules/QualityRules";
import CreateQualityRules from "../Pages/QualityRules/CreateQualityRules";
import EditQualityRules from "../Pages/QualityRules/EditQualityRules";
import QualityRuleAssociation from "../Pages/QualityRuleAssociation/QualityRuleAssociation";
import CreateQualityRuleAssociation from "../Pages/QualityRuleAssociation/CreateQualityRuleAssociation";
import EditQualityRuleAssociation from "../Pages/QualityRuleAssociation/EditQualityRuleAssociation";
import QualityParameter from "../Pages/QualityParameters/QualityParameter";
import CreateQualityParameter from "../Pages/QualityParameters/CreateQualityParameter";
import EditQualityParameter from "../Pages/QualityParameters/EditQualityParameter";
import Operator from "../Pages/Operator/Operator";
import CreateOperator from "../Pages/Operator/CreateOperator";
import EditOperator from "../Pages/Operator/EditOperator";
import DailyProductionReport from "../Pages/DailyProductionReport/DailyProductionReport";
import CreateDailyProductionReport from "../Pages/DailyProductionReport/CreateDailyProductionReport";
import EditDailyProductionReport from "../Pages/DailyProductionReport/EditDailyProductionReport";
import MachineStatus from "../Pages/MachineStatus/MachineStatus";
import CreateMachineStatus from "../Pages/MachineStatus/CreateMachineStatus";
import EditMachineStatus from "../Pages/MachineStatus/EditMachineStatus";
import QC from "../Pages/QC/QC";
import QualityCheckSelection from "../Pages/QC/QualityCheckSelection";
import QualitySampleSize from "../Pages/QC/QualitySampleSize";
import ItemParameterAssociation from "../Pages/ItemParameterAssociation/ItemParameterAssociation";
import CreateItemParameterAssociation from "../Pages/ItemParameterAssociation/CreateItemParameterAssociation";
import EditItemParameterAssociation from "../Pages/ItemParameterAssociation/EditItemParameterAssociation";
import QualityCheckResults from "../Pages/QualityCheckResults/QualityCheckResults";
import CreateQualityCheckResults from "../Pages/QualityCheckResults/CreateQualityCheckResults";
import EditQualityCheckResults from "../Pages/QualityCheckResults/EditQualityCheckResults";
import SamplingPlan from "../Pages/SamplingPlan/SamplingPlan";
import CreateSamplingPlan from "../Pages/SamplingPlan/CreateSamplingPlan";
import EditSamplingPlan from "../Pages/SamplingPlan/EditSamplingPlan";

const Content = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  return (
    <>
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <Routes>
              {privileges.includes("sales_order_retrieve") ? (
                <Route path="/" element={<SalesOrder />} />
              ) : (
                <Route path="/" element={<NotFound />} />
              )}
              {privileges.includes("sales_order_create") ? (
                <Route
                  path="/create-sales-order"
                  element={<CreateSalesOrder />}
                />
              ) : (
                <Route path="/create-sales-order" element={<NotFound />} />
              )}
              {privileges.includes("sales_order_update") ? (
                <Route path="/edit-sales-order" element={<EditSalesOrder />} />
              ) : (
                <Route path="/edit-sales-order" element={<NotFound />} />
              )}

              {privileges.includes("daily_plan") ? (
                <Route path="/daily-plan" element={<DailyPlan />} />
              ) : (
                <Route path="/daily-plan" element={<NotFound />} />
              )}

              {privileges.includes("qc_screen") ? (
                <Route path="/qc" element={<QC />} />
              ) : (
                <Route path="/qc" element={<QC />} />
              )}

              {privileges.includes("qc_screen") ? (
                <Route
                  path="/item-selection"
                  element={<QualityCheckSelection />}
                />
              ) : (
                <Route path="/item-selection" element={<QC />} />
              )}

              {privileges.includes("qc_screen") ? (
                <Route path="/sample-size" element={<QualitySampleSize />} />
              ) : (
                <Route path="/item-selection" element={<QC />} />
              )}

              {privileges.includes("client_retrieve") ? (
                <Route path="/client" element={<Client />} />
              ) : (
                <Route path="/client" element={<NotFound />} />
              )}
              {privileges.includes("client_create") ? (
                <Route path="/create-client" element={<CreateClient />} />
              ) : (
                <Route path="/create-client" element={<NotFound />} />
              )}
              {privileges.includes("client_update") ? (
                <Route path="/edit-client" element={<EditClient />} />
              ) : (
                <Route path="/edit-client" element={<NotFound />} />
              )}

              {privileges.includes("bom_retrieve") ? (
                <Route path="/bom" element={<BOM />} />
              ) : (
                <Route path="/bom" element={<NotFound />} />
              )}
              {privileges.includes("bom_create") ? (
                <Route path="/create-bom" element={<CreateBOM />} />
              ) : (
                <Route path="/create-bom" element={<NotFound />} />
              )}
              {privileges.includes("bom_update") ? (
                <Route path="/edit-bom" element={<EditBOM />} />
              ) : (
                <Route path="/edit-bom" element={<NotFound />} />
              )}

              {privileges.includes("item_retrieve") ? (
                <Route path="/item" element={<Product />} />
              ) : (
                <Route path="/item" element={<NotFound />} />
              )}
              {privileges.includes("item_create") ? (
                <Route path="/create-item" element={<CreateProduct />} />
              ) : (
                <Route path="/create-item" element={<NotFound />} />
              )}
              {privileges.includes("item_update") ? (
                <Route path="/edit-item" element={<EditProduct />} />
              ) : (
                <Route path="/edit-item" element={<NotFound />} />
              )}

              {privileges.includes("work_order_split") ? (
                <Route path="/sales-work-order" element={<WorkOrderSplit />} />
              ) : (
                <Route path="/sales-work-order" element={<NotFound />} />
              )}

              {privileges.includes("work_order_retrieve") ? (
                <Route path="/work-order" element={<WorkOrder />} />
              ) : (
                <Route path="/work-order" element={<NotFound />} />
              )}
              {privileges.includes("work_order_create") ? (
                <Route
                  path="/create-work-order"
                  element={<CreateWorkOrder />}
                />
              ) : (
                <Route path="/create-work-order" element={<NotFound />} />
              )}
              {privileges.includes("work_order_update") ? (
                <Route path="/edit-work-order" element={<EditWorkOrder />} />
              ) : (
                <Route path="/edit-work-order" element={<NotFound />} />
              )}

              {privileges.includes("supplier_retrieve") ? (
                <Route path="/supplier" element={<Supplier />} />
              ) : (
                <Route path="/supplier" element={<NotFound />} />
              )}
              {privileges.includes("supplier_create") ? (
                <Route path="/create-supplier" element={<CreateSupplier />} />
              ) : (
                <Route path="/create-supplier" element={<NotFound />} />
              )}
              {privileges.includes("supplier_update") ? (
                <Route path="/edit-supplier" element={<EditSupplier />} />
              ) : (
                <Route path="/edit-supplier" element={<NotFound />} />
              )}

              {privileges.includes("machine_retrieve") ? (
                <Route path="/machine" element={<Machine />} />
              ) : (
                <Route path="/machine" element={<NotFound />} />
              )}
              {privileges.includes("machine_create") ? (
                <Route path="/create-machine" element={<CreateMachine />} />
              ) : (
                <Route path="/create-machine" element={<NotFound />} />
              )}
              {privileges.includes("machine_update") ? (
                <Route path="/edit-machine" element={<EditMachine />} />
              ) : (
                <Route path="/edit-machine" element={<NotFound />} />
              )}

              {privileges.includes("client_item_retrieve") ? (
                <Route
                  path="/client-item"
                  element={<ClientItemAssociation />}
                />
              ) : (
                <Route path="/client-item" element={<NotFound />} />
              )}
              {privileges.includes("client_item_create") ? (
                <Route
                  path="/create-client-item"
                  element={<CreateClientItemAssociation />}
                />
              ) : (
                <Route path="/create-client-item" element={<NotFound />} />
              )}
              {privileges.includes("client_item_update") ? (
                <Route
                  path="/edit-client-item"
                  element={<EditClientItemAssociation />}
                />
              ) : (
                <Route path="/edit-client-item" element={<NotFound />} />
              )}

              {privileges.includes("supplier_item_retrieve") ? (
                <Route
                  path="/supplier-item"
                  element={<SupplierItemAssociation />}
                />
              ) : (
                <Route path="/supplier-item" element={<NotFound />} />
              )}
              {privileges.includes("supplier_item_create") ? (
                <Route
                  path="/create-supplier-item"
                  element={<CreateSupplierItemAssociation />}
                />
              ) : (
                <Route path="/create-supplier-item" element={<NotFound />} />
              )}
              {privileges.includes("supplier_item_update") ? (
                <Route
                  path="/edit-supplier-item"
                  element={<EditSupplierItemAssociation />}
                />
              ) : (
                <Route path="/edit-supplier-item" element={<NotFound />} />
              )}

              {privileges.includes("bo_retrieve") ? (
                <Route path="/bo" element={<BO />} />
              ) : (
                <Route path="/bo" element={<NotFound />} />
              )}
              {privileges.includes("bo_create") ? (
                <Route path="/create-bo" element={<CreateBO />} />
              ) : (
                <Route path="/create-bo" element={<NotFound />} />
              )}
              {privileges.includes("bo_update") ? (
                <Route path="/edit-bo" element={<EditBO />} />
              ) : (
                <Route path="/edit-bo" element={<NotFound />} />
              )}

              {privileges.includes("operator_retrieve") ? (
                <Route path="/operator" element={<Operator />} />
              ) : (
                <Route path="/operator" element={<NotFound />} />
              )}
              {privileges.includes("operator_create") ? (
                <Route path="/create-operator" element={<CreateOperator />} />
              ) : (
                <Route path="/create-operator" element={<NotFound />} />
              )}
              {privileges.includes("operator_update") ? (
                <Route path="/edit-operator" element={<EditOperator />} />
              ) : (
                <Route path="/edit-operator" element={<NotFound />} />
              )}

              {privileges.includes("sampling_plan_retrieve") ? (
                <Route path="/sampling-plan" element={<SamplingPlan />} />
              ) : (
                <Route path="/sampling-plan" element={<NotFound />} />
              )}
              {privileges.includes("sampling_plan_create") ? (
                <Route
                  path="/create-sampling-plan"
                  element={<CreateSamplingPlan />}
                />
              ) : (
                <Route path="/create-sampling-plan" element={<NotFound />} />
              )}
              {privileges.includes("sampling_plan_update") ? (
                <Route
                  path="/edit-sampling-plan"
                  element={<EditSamplingPlan />}
                />
              ) : (
                <Route path="/edit-sampling-plan" element={<NotFound />} />
              )}

              {privileges.includes("item_parameter_retrieve") ? (
                <Route
                  path="/item-parameter"
                  element={<ItemParameterAssociation />}
                />
              ) : (
                <Route path="/item-parameter" element={<NotFound />} />
              )}
              {privileges.includes("item_parameter_create") ? (
                <Route
                  path="/create-item-parameter"
                  element={<CreateItemParameterAssociation />}
                />
              ) : (
                <Route path="/create-item-parameter" element={<NotFound />} />
              )}
              {privileges.includes("item_parameter_update") ? (
                <Route
                  path="/edit-item-parameter"
                  element={<EditItemParameterAssociation />}
                />
              ) : (
                <Route path="/edit-item-parameter" element={<NotFound />} />
              )}

              {privileges.includes("daily_production_retrieve") ? (
                <Route
                  path="/daily-production-report"
                  element={<DailyProductionReport />}
                />
              ) : (
                <Route path="/daily-production-report" element={<NotFound />} />
              )}
              {privileges.includes("daily_production_create") ? (
                <Route
                  path="/create-daily-production-report"
                  element={<CreateDailyProductionReport />}
                />
              ) : (
                <Route
                  path="/create-daily-production-report"
                  element={<NotFound />}
                />
              )}
              {privileges.includes("daily_production_update") ? (
                <Route
                  path="/edit-daily-production-report"
                  element={<EditDailyProductionReport />}
                />
              ) : (
                <Route
                  path="/edit-daily-production-report"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("machine_status_retrieve") ? (
                <Route path="/machine-status" element={<MachineStatus />} />
              ) : (
                <Route path="/machine-status" element={<NotFound />} />
              )}
              {privileges.includes("machine_status_create") ? (
                <Route
                  path="/create-machine-status"
                  element={<CreateMachineStatus />}
                />
              ) : (
                <Route path="/create-machine-status" element={<NotFound />} />
              )}
              {privileges.includes("machine_status_update") ? (
                <Route
                  path="/edit-machine-status"
                  element={<EditMachineStatus />}
                />
              ) : (
                <Route path="/edit-machine-status" element={<NotFound />} />
              )}

              {privileges.includes("quality_check_retrieve") ? (
                <Route
                  path="/quality-check"
                  element={<QualityCheckResults />}
                />
              ) : (
                <Route path="/quality-check" element={<NotFound />} />
              )}
              {privileges.includes("quality_check_create") ? (
                <Route
                  path="/create-quality-check"
                  element={<CreateQualityCheckResults />}
                />
              ) : (
                <Route path="/create-quality-check" element={<NotFound />} />
              )}
              {privileges.includes("quality_check_update") ? (
                <Route
                  path="/edit-quality-check"
                  element={<EditQualityCheckResults />}
                />
              ) : (
                <Route path="/edit-quality-check" element={<NotFound />} />
              )}

              {privileges.includes("quality_rules_retrieve") ? (
                <Route path="/quality-rules" element={<QualityRules />} />
              ) : (
                <Route path="/quality-rules" element={<NotFound />} />
              )}
              {privileges.includes("quality_rules_create") ? (
                <Route
                  path="/create-quality-rules"
                  element={<CreateQualityRules />}
                />
              ) : (
                <Route path="/create-quality-rules" element={<NotFound />} />
              )}
              {privileges.includes("quality_rules_update") ? (
                <Route
                  path="/edit-quality-rules"
                  element={<EditQualityRules />}
                />
              ) : (
                <Route path="/edit-quality-rules" element={<NotFound />} />
              )}

              {privileges.includes("quality_parameter_retrieve") ? (
                <Route
                  path="/quality-parameter"
                  element={<QualityParameter />}
                />
              ) : (
                <Route path="/quality-parameter" element={<NotFound />} />
              )}
              {privileges.includes("quality_parameter_create") ? (
                <Route
                  path="/create-quality-parameter"
                  element={<CreateQualityParameter />}
                />
              ) : (
                <Route
                  path="/create-quality-parameter"
                  element={<NotFound />}
                />
              )}
              {privileges.includes("quality_parameter_update") ? (
                <Route
                  path="/edit-quality-parameter"
                  element={<EditQualityParameter />}
                />
              ) : (
                <Route path="/edit-quality-parameter" element={<NotFound />} />
              )}

              {privileges.includes("quality_rule_association_retrieve") ? (
                <Route
                  path="/quality-rule-association"
                  element={<QualityRuleAssociation />}
                />
              ) : (
                <Route
                  path="/quality-rule-association"
                  element={<NotFound />}
                />
              )}
              {privileges.includes("quality_rule_association_create") ? (
                <Route
                  path="/create-quality-rule-association"
                  element={<CreateQualityRuleAssociation />}
                />
              ) : (
                <Route
                  path="/create-quality-rule-association"
                  element={<NotFound />}
                />
              )}
              {privileges.includes("quality_rule_association_update") ? (
                <Route
                  path="/edit-quality-rule-association"
                  element={<EditQualityRuleAssociation />}
                />
              ) : (
                <Route
                  path="/edit-quality-rule-association"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("stock_transaction_retrieve") ? (
                <Route
                  path="/stock-transaction"
                  element={<StockTransaction />}
                />
              ) : (
                <Route path="/stock-transaction" element={<NotFound />} />
              )}
              {privileges.includes("stock_transaction_create") ? (
                <Route
                  path="/create-stock-transaction"
                  element={<CreateStockTransaction />}
                />
              ) : (
                <Route
                  path="/create-stock-transaction"
                  element={<NotFound />}
                />
              )}
              {privileges.includes("stock_transaction_update") ? (
                <Route
                  path="/edit-stock-transaction"
                  element={<EditStockTransaction />}
                />
              ) : (
                <Route path="/edit-stock-transaction" element={<NotFound />} />
              )}

              {privileges.includes("role_retrieve") ? (
                <Route path="/role" element={<Role />} />
              ) : (
                <Route path="/role" element={<NotFound />} />
              )}
              {privileges.includes("role_create") ? (
                <Route path="/create-role" element={<CreateRole />} />
              ) : (
                <Route path="/create-role" element={<NotFound />} />
              )}
              {privileges.includes("role_update") ? (
                <Route path="/edit-role" element={<EditRole />} />
              ) : (
                <Route path="/edit-role" element={<NotFound />} />
              )}

              {privileges.includes("privilege_retrieve") ? (
                <Route path="/privilege" element={<Privilege />} />
              ) : (
                <Route path="/privilege" element={<NotFound />} />
              )}
              {privileges.includes("privilege_create") ? (
                <Route path="/create-privilege" element={<CreatePrivilege />} />
              ) : (
                <Route path="/create-privilege" element={<NotFound />} />
              )}
              {privileges.includes("privilege_update") ? (
                <Route path="/edit-privilege" element={<EditPrivilege />} />
              ) : (
                <Route path="/edit-privilege" element={<NotFound />} />
              )}

              {privileges.includes("type_master_retrieve") ? (
                <Route path="/type-master" element={<TypeMaster />} />
              ) : (
                <Route path="/type-master" element={<NotFound />} />
              )}
              {privileges.includes("type_master_create") ? (
                <Route
                  path="/create-type-master"
                  element={<CreateTypeMaster />}
                />
              ) : (
                <Route path="/create-type-master" element={<NotFound />} />
              )}
              {privileges.includes("type_master_update") ? (
                <Route path="/edit-type-master" element={<EditTypeMaster />} />
              ) : (
                <Route path="/edit-type-master" element={<NotFound />} />
              )}

              {privileges.includes("type_master_category_retrieve") ? (
                <Route
                  path="/type-master-category"
                  element={<TypeMasterCategory />}
                />
              ) : (
                <Route path="/type-master-category" element={<NotFound />} />
              )}
              {privileges.includes("type_master_category_create") ? (
                <Route
                  path="/create-type-master-category"
                  element={<CreateTypeMasterCategory />}
                />
              ) : (
                <Route
                  path="/create-type-master-category"
                  element={<NotFound />}
                />
              )}
              {privileges.includes("type_master_category_update") ? (
                <Route
                  path="/edit-type-master-category"
                  element={<EditTypeMasterCategory />}
                />
              ) : (
                <Route
                  path="/edit-type-master-category"
                  element={<NotFound />}
                />
              )}
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;