import React from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const item = [
  { label: "Flipkart Brush", value: 1 },
  { label: "Orbit Brush", value: 2 },
  { label: "Amazone Brush", value: 3 },
  { label: "Oral B Brush", value: 4 },
];

const dpo = [
  { label: "Flipkart Brush", value: 1 },
  { label: "Orbit Brush", value: 2 },
];

const QualityCheckSelection = () => {
  const navigate = useNavigate();
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isValid } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Quality Check </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Select Item
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((info) => ({
                          value: info.value,
                          label: info.label,
                        }))}
                        // isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item"
                    control={control}
                  />
                  {errors.item && (
                    <span className="fs-8 text-danger">Item is required</span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Daily/ Purchased order
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={dpo?.map((info) => ({
                          value: info.value,
                          label: info.label,
                        }))}
                        // isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-daily-purchased-order"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="daily_purchased_order"
                    control={control}
                  />
                  {errors.daily_purchased_order && (
                    <span className="fs-8 text-danger">Daily Purchased Order is required</span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {/* {isPending ? (
                      <button
                        type="button"
                        id="button-typemaster-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : ( */}
                    <button
                      type="submit"
                      className="btn btn-md btn-primary"
                      id="submit-button-create-typemaster"
                      disabled={!isValid}
                    >
                      Submit
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualityCheckSelection;
