import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditWorkOrder = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // dropdown
  const [salesOrder, setSalesOrder] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime : 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "work_order",
    endpoint: "/api/work-order/",
  });

  const handleUpdateWorkOrder = (data) => {
    Swal.fire({
      title: "Work Order Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorWorkOrder = (data) => {
    Swal.fire({
      title: "Error Updating Work Order",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateWorkOrder,
    onError: handleErrorWorkOrder,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var work_order = {
      sales_order: data.sales_order.value,
      prod_start_date: data.prod_start_date,
      dispatch_date: data.dispatch_date,
      target_quantity: data.target_quantity,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: work_order,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editWorkOrder = location.state.editpost;
    // defaultValues is a react form hook.
    if (editWorkOrder) {
      var defaultValues = {};
      defaultValues.prod_start_date = editWorkOrder.prod_start_date;
      defaultValues.dispatch_date = editWorkOrder.dispatch_date;
      defaultValues.target_quantity = editWorkOrder.target_quantity;

      const getSalesOrder = {
        value: editWorkOrder.sales_order?.id,
        label: editWorkOrder.sales_order?.so_number,
      };
      defaultValues.salesorder = getSalesOrder;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setSalesOrder(data.sales_order);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Work Order </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sales Order Name<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={salesOrder?.map((api) => {
                          return { label: api.so_number, value: api.id };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-sales-order"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="salesorder"
                    control={control}
                  />
                  {errors.salesorder && (
                    <span className="fs-8 text-danger">
                      Sales Order is Required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Production Start Date
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="email-forms-prod_start_date"
                    {...register("prod_start_date", { required: true })}
                  />
                  {errors.prod_start_date && (
                    <span className="fs-8 text-danger">
                      Production start date is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Dispatch Date.<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="email-forms-dispatch_date"
                    {...register("dispatch_date", { required: true })}
                  />
                  {errors.dispatch_date && (
                    <span className="fs-8 text-danger">
                      Dispatch date is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Target Quantity
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter your target quantity"
                    id="number-forms-target_quantity"
                    {...register("target_quantity", {
                      required: "Target Quantity is required",
                      validate: {
                        positive: (value) =>
                          value > 0 || "Only positive numbers are allowed",
                        integer: (value) =>
                          Number.isInteger(parseFloat(value)) ||
                          "Only integers are allowed",
                      },
                    })}
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.target_quantity && (
                    <span className="fs-8 text-danger">
                      {errors.target_quantity.message}
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-work-order-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-work-order"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWorkOrder;
