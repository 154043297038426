import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditQualityCheckResults = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // select dropdown
  const [parameter, setParameter] = useState([]);
  const [item, setItem] = useState([]);
  const [qualitybatch, setQualitybatch] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "quality_check",
    endpoint: "/api/quality-check-results/",
  });

  const handleUpdateQualityCheck = (data) => {
    Swal.fire({
      title: "Quality Check Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorQualityCheck = (data) => {
    Swal.fire({
      title: "Error Updating Quality Check",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateQualityCheck,
    onError: handleErrorQualityCheck,
    retry: 2,
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var quality_check = {
      parameter_id: data.parameter_id.value,
      item_id: data.item_id.value,
      qc_batch_id: data.qc_batch_id.value,
      expected_result: data.expected_result,
      actual_result: data.actual_result,
      comment: data.comment,
      is_approved: data.is_approved,
      updated_by: userID,
    };

    mutate({
      id: location.state.editpost.id,
      data: quality_check,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editQualityCheck = location.state.editpost;
    // defaultValues is a react form hook.
    if (editQualityCheck) {
      var defaultValues = {};
      defaultValues.expected_result = editQualityCheck.expected_result;
      defaultValues.actual_result = editQualityCheck.actual_result;
      defaultValues.comment = editQualityCheck.comment;
      defaultValues.is_approved = editQualityCheck.is_approved;

      const getItem = {
        value: editQualityCheck.item?.id,
        label: editQualityCheck.item?.item_name,
      };
      defaultValues.item_id = getItem;

      const getParameter = {
        value: editQualityCheck.parameter.id,
        label: editQualityCheck.parameter.parameter_name,
      };
      defaultValues.parameter_id = getParameter;

      const getQualityBatch = {
        value: editQualityCheck.qc_batch.id,
        label: editQualityCheck.qc_batch.batch_code,
      };
      defaultValues.qc_batch_id = getQualityBatch;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
      if (data) {
        setParameter(data?.quality_parameter);
        setQualitybatch(data?.quality_batch);
        setItem(data.item);
      }
    }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Quality Check </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Parameter Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={parameter?.map((api) => ({
                          value: api.id,
                          label: api.parameter_name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-parameter_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="parameter_id"
                    control={control}
                  />
                  {errors.parameter_id && (
                    <span className="fs-8 text-danger">
                      Parameter Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Quality Batch Code
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={qualitybatch?.map((api) => ({
                          value: api.id,
                          label: api.batch_code,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-qc_batch_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="qc_batch_id"
                    control={control}
                  />
                  {errors.qc_batch_id && (
                    <span className="fs-8 text-danger">
                      Quality batch code is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((info) => ({
                          value: info.item?.id,
                          label: info.item?.item_name,
                        }))}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item-id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Expected Result
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter expected result"
                    id="text-forms-expected-result"
                    {...register("expected_result", { required: true })}
                  />
                  {errors.expected_result && (
                    <span className="fs-8 text-danger">
                      Expected Result is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Actual Result
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter actual result"
                    id="text-forms-actual-result"
                    {...register("actual_result", { required: true })}
                  />
                  {errors.actual_result && (
                    <span className="fs-8 text-danger">
                      Actual Result is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Comments
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter comments"
                    id="text-forms-comments"
                    {...register("comment", { required: true })}
                  ></textarea>
                  {errors.comment && (
                    <span className="fs-8 text-danger">
                      Comments is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div class="custom-control custom-checkbox">
                    <label class="custom-control-label form-label" for="is_approved">
                      Is Approved
                    </label>
                    <div>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="is_approved"
                        {...register("is_approved", { required: true })}
                      />
                    </div>
                  </div>
                  {errors.is_approved && (
                    <span className="fs-8 text-danger">
                      Is Approved is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-quality-check-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-quality-check"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditQualityCheckResults;