import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditOperator = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // select dropdown
  const [user, setUser] = useState([]);
  const [department, setDepartment] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { useUpdate } = useAPIQuery({
    key: "operator",
    endpoint: "/api/operator/",
  });

  const handleUpdateOperator = (data) => {
    Swal.fire({
      title: "Operator Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorOperator = (data) => {
    Swal.fire({
      title: "Error Updating Operator",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateOperator,
    onError: handleErrorOperator,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var operator = {
      user_id: data.user_id.value,
      department: data.department.value,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: operator,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editOperator = location.state.editpost;
    // defaultValues is a react form hook.
    if (editOperator) {
      var defaultValues = {};
      const getUser = {
        value: editOperator.user?.id,
        label:
          editOperator.user?.first_name + " " + editOperator.user?.last_name,
      };
      defaultValues.user_id = getUser;

      const getDepartment = {
        value: editOperator.department,
        label: editOperator.department,
      };
      defaultValues.department = getDepartment;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data.user);
      setDepartment(
        data?.typemaster?.filter(
          (post) => post.category_value == "operator_department"
        )
      );
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Operator </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    User <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={user?.map((api) => {
                          return {
                            value: api.id,
                            label: api?.first_name + " " + api?.last_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-user"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user_id"
                    control={control}
                  />
                  {errors.user_id && (
                    <span className="fs-8 text-danger">
                      User Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Department <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={department?.map((api) => {
                          return {
                            value: api.name,
                            label: api.name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-department"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="department"
                    control={control}
                  />
                  {errors.department && (
                    <span className="fs-8 text-danger">
                      Department name is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-operator-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-operator"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOperator;