import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { Table } from "@dev2_techwalnut/tw-react-crud-dashlite";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";

const columns = [
  {
    accessor: "parameter",
    header: "Parameter",
    cell: (row) => <>{row?.parameter?.parameter_name}</>,
  },
  {
    accessor: "lower_limit",
    header: "Lower Limit",
  },
  {
    accessor: "higher_limit",
    header: "Higher Limit",
  },
  {
    accessor: "tolerance",
    header: "Tolerance",
  },
  {
    accessor: "expected_result",
    header: "Expected Result",
  },
];

const QualityRules = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [qualityEntries, setQualityEntries] = useState(5);
  const [qualityPageIndex, setQualityPageIndex] = useState(0);
  const [search, setSearch] = useState("");

  const { useFetch } = useAPIQuery({
    key: "quality_rules",
    endpoint: "/api/quality-rules/",
  });

  const { data, isLoading } = useFetch({
    params : {
      entries : qualityEntries,
      page_no : qualityPageIndex + 1,
      event : search ? search : undefined
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime : 1000 * 60 * 3,
    retry : 3,
    keepPlaceholder : true,
    refetchOnReconnect : true
  });

  const { useDelete } = useAPIQuery({
    key: "quality_rules",
    endpoint: "/api/quality-rules/",
  });

  const { mutate: deleteData } = useDelete();

  const handleGlobalFilterChange = (value) => {
    setSearch(value);
  };

  const handlePagination = (value) => {
    console.log(value);
    setQualityPageIndex(value.pageIndex);
    setQualityEntries(value.pageSize);
  };

  const handleEditRow = (row) => {
    console.log(row);
    navigate("/edit-quality-rules", { state: { editpost: row.original } });
  };

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row.original.id;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData({ id: entry_id });
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Quality Rules</h3>
            {isLoading ? (
              <div className="spinner-border text-primary ms-2" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("quality_rules_create") && (<div className="nk-block-head-content">
            <NavLink
              to="/create-quality-rules"
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-create-quality-rules"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to="/create-quality-rules"
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-create-quality-rules"
            >
              <em className="icon ni ni-plus"></em>
              <span>Create Quality Rules</span>
            </NavLink>
          </div>)}
        </div>
      </div>

      <Table
        data={data ? data.results.data : []}
        columns={columns}
        pagination
        pageIndex={qualityPageIndex}
        pageSize={qualityEntries}
        rowCount={data ? data.count : 0}
        globalSearch
        editRows={privileges.includes("quality_rules_update") ? true : false}
        deleteRows={privileges.includes("quality_rules_delete") ? true : false}
        filterModal={false}
        selectRows={false}
        onGlobalFilterChange={handleGlobalFilterChange}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        onPaginationChange={handlePagination}
      />
    </>
  );
};

export default QualityRules;