import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const CreateBOM = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();

  // select dropdown
  const [productData, setProductData] = useState([]);
  const [variant, setVariant] = useState([]);
  const [item, setItem] = useState([]);
  const [department, setDepartment] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useCreate } = useAPIQuery({
    key: "bom",
    endpoint: "/api/bom/",
  });

  const handleCreateBOM = (data) => {
    Swal.fire({
      title: "BOM Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorBOM = (data) => {
    Swal.fire({
      title: "Error Adding BOM",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: handleCreateBOM,
    onError: handleErrorBOM,
    retry: 2,
  });

  // react Hook form Props
  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bulk_order: [
        {
          item_id: "",
          department: "",
          req_quan_of_item: "",
          item_per_product: "",
          supplier: "",
        },
      ],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "bulk_order",
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    console.log(data);
    var bom = {
      product_id: data.product_id.value,
      variant: data.variant.value,
      no_shades: data.no_shades,
      created_by: userID,
      updated_by: userID,
      bulk_upload: data.bulk_order.map((api) => {
        return {
          item_id: api.item_id?.value,
          department: api.department?.value,
          req_quan_of_item: api.req_quan_of_item,
          item_per_product: api.item_per_product,
          supplier_id: api.supplier?.value,
        };
      }),
    };

    mutate({
      data: bom,
    });
  };

  useEffect(() => {
    if (data) {
      setProductData(
        data.item.filter(
          (api) => api.category === "FG" || api.category === "WIP"
        )
      );
      setVariant(
        data.typemaster?.filter((post) => post.category_value == "variant")
      );
      setItem(data.item.filter((api) => api.category !== "FG"));
      setDepartment(
        data.typemaster?.filter(
          (post) => post.category_value == "bom_department"
        )
      );
      setSupplier(data.supplier);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create BOM </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Product<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={productData?.map((api) => {
                          return { label: api.item_name, value: api.id };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-product_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="product_id"
                    control={control}
                  />
                  {errors.product_id && (
                    <span className="fs-8 text-danger">
                      Product is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Variant<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={variant?.map((api) => {
                          return { value: api.name, label: api.name };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-variant"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="variant"
                    control={control}
                  />
                  {errors.variant && (
                    <span className="fs-8 text-danger">
                      Variant is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    No of Shades<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter no of shades"
                    id="text-forms-no_shades"
                    {...register("no_shades", { required: true })}
                  />
                  {errors.no_shades && (
                    <span className="fs-8 text-danger">
                      No of Shades is required
                    </span>
                  )}
                </div>

                <div className="border"></div>

                {fields.map((field, index) => (
                  <div className="row g-4" key={field.id}>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Item<span className="fs-8 text-danger"> *</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={item?.map((api) => {
                              return { value: api.id, label: api.item_name };
                            })}
                            isLoading={isLoading}
                            isClearable={true}
                            id="select-forms-item_id"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name={`bulk_order.${index}.item_id`}
                        control={control}
                      />
                      {errors.bulk_order?.[index]?.item_id && (
                        <span className="fs-8 text-danger">
                          Item is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Department<span className="fs-8 text-danger"> *</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={department?.map((api) => {
                              return { value: api.name, label: api.name };
                            })}
                            isLoading={isLoading}
                            isClearable={true}
                            id="select-forms-department"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name={`bulk_order.${index}.department`}
                        control={control}
                      />
                      {errors.bulk_order?.[index]?.department && (
                        <span className="fs-8 text-danger">
                          Department is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Required Quantity of Item
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Enter required quantity of item"
                        id="email-forms-req_quan_of_item"
                        {...register(`bulk_order.${index}.req_quan_of_item`, {
                          required: "Required Quantity of Item is required",
                          validate: {
                            positive: (value) =>
                              value > 0 || "Only positive numbers are allowed",
                          },
                        })}
                        onWheel={(e) => e.target.blur()}
                      />
                      {errors.bulk_order?.[index]?.req_quan_of_item && (
                        <span className="fs-8 text-danger">
                          {errors.bulk_order?.[index]?.req_quan_of_item.message}
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Item Per Product
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Enter item per product"
                        id="email-forms-item_per_product"
                        {...register(`bulk_order.${index}.item_per_product`, {
                          required: "Item Per Product is required",
                          validate: {
                            positive: (value) =>
                              value > 0 || "Only positive numbers are allowed",
                          },
                        })}
                        onWheel={(e) => e.target.blur()}
                      />
                      {errors.bulk_order?.[index]?.item_per_product && (
                        <span className="fs-8 text-danger">
                          {errors.bulk_order?.[index]?.item_per_product.message}
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Supplier Name
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={supplier?.map((api) => {
                              return {
                                value: api.id,
                                label: api.first_name + " " + api.last_name,
                              };
                            })}
                            isLoading={isLoading}
                            isClearable={true}
                            id="select-forms-supplier"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name={`bulk_order.${index}.supplier`}
                        control={control}
                      />
                      {errors.bulk_order?.[index]?.item_per_product && (
                        <span className="fs-8 text-danger">
                          Supplier Name is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-3 col-sm-12">
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-md me-3 btn-outline-primary"
                          onClick={() =>
                            append({
                              item_id: "",
                              department: "",
                              req_quan_of_item: "",
                              item_per_product: "",
                              supplier: "",
                            })
                          }
                        >
                          Add
                        </button>
                        {index !== 0 && (
                          <button
                            type="button"
                            className="btn btn-md btn-outline-danger"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-bom-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-create-bom"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBOM;
