import axios from "axios";
import React, { useContext, useState } from "react";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import { useGoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { GlobalContext } from "../Context/GlobalContext";

const Login = () => {
  const { useCreate } = useAPIQuery({
    key: "login",
    endpoint: "/authentication/login-api/",
  });

  const { loginHandler } = useContext(GlobalContext)
  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Password show/hide state to handle
  const [showPassword, setShowPassword] = useState(false);

  const onLoginHandle = (data) => {
    console.log("Create successful:", data);
    const userData = data.data?.[0];
    console.log(userData);
    loginHandler(userData);
  };

  const errorHandler = (data) => {
    console.log(data);
    Swal.fire({
      title: "Error while logging.",
      text: data?.response?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useCreate({
    onSuccess: onLoginHandle,
    onError: errorHandler,
  });

  const onSubmit = (data) => {
    let user = {
      username: data.username,
      password: data.password,
    };

    mutate({
      data: user,
    });
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const user = codeResponse;
      checkGoogleAuthentication(user);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const checkGoogleAuthentication = (user) => {
    console.log(user);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          let profile = res.data;
          console.log(profile);

          let google_user = {
            email: profile.email,
            first_name: profile.given_name,
            last_name: profile.family_name,
            is_google_login: true,
          }

          mutate({
            data: google_user,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <a href="javacsript:void(0)" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src="./assets/images/gev-logo-dark.png"
                      srcSet="./assets/images/gev-logo-dark.png 2x"
                      alt="gev-logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src="./assets/images/gev-logo-dark.png"
                      srcSet="./assets/images/gev-logo-dark.png 2x"
                      alt="gev-logo"
                    />
                  </a>
                </div>
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title text-center">Login</h4>
                        <div className="nk-block-des">
                          <p>
                            Access the Green Edge Venture Application using your
                            username and password.
                          </p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Username
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="text-forms-text"
                            placeholder="Enter your username"
                            {...register("username", {
                              required: "Username is required",
                              validate: (value) =>
                                value === value.toLowerCase() ||
                                "Username should only contain lowercase letters",
                            })}
                          />
                        </div>
                        {errors.username && (
                          <span className="fs-8 text-danger">
                            {errors?.username?.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label
                            className="form-label"
                            htmlFor="password-forms-password"
                          >
                            Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="javascript:void(0)"
                            onClick={() => setShowPassword(!showPassword)}
                            className={`${
                              showPassword === true
                                ? "form-icon form-icon-right passcode-switch lg is-shown"
                                : "form-icon form-icon-right passcode-switch lg"
                            }`}
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type={`${
                              showPassword === true ? "text" : "password"
                            }`}
                            className={`${
                              showPassword === true
                                ? "form-control form-control-lg valid is-shown"
                                : "form-control form-control-lg"
                            }`}
                            id="password-forms-password"
                            placeholder="Enter your passcode"
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                        </div>
                        {errors.password && (
                          <span className="fs-8 text-danger">
                            {errors?.password?.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        {isPending === true ? (
                          <button
                            id="button-loading"
                            className="btn btn-lg btn-primary btn-block"
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary btn-block"
                            id="submit-button-login"
                            atl="signin"
                          >
                            Login
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="text-center pt-4 pb-3">
                      <h6 className="overline-title overline-title-sap">
                        <span>OR</span>
                      </h6>
                    </div>
                    <ul className="nav justify-center gx-4">
                      <li className="nav-item">
                        <a
                          className="btn btn-primary"
                          href="#"
                          onClick={() => googleLoginHandler()}
                          id="button-google-authentication"
                        >
                          Sign in with Google
                          <em className="ni ni-google ms-1"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
    </>
  );
};

export default Login;
