import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import React, { useMemo, useState } from "react";
import DailyPlanCalendar from "./DailyPlanCalendar";
import Error from "../../ErrorPages/Error";

const DailyPlan = () => {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleAccordionClick = (machineId) => {
        setActiveAccordion((prev) => (prev === machineId ? null : machineId));
    };

    const { useFetch: useFetchMachinesHook } = useAPIQuery({
        key: "machines",
        endpoint: "/api/machine-list/",
    });
    const { data, isLoading, isError } = useFetchMachinesHook();
    const machines = useMemo(() => {
        if (data) {
            return data.data;
        }
    }, [data]);
    if (isLoading) return <div>Loading...</div>;
    if (isError || !data) return <Error />;
    return (
        <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
                <div className="nk-block-head-content d-flex">
                    <h3 className="nk-block-title page-title">Daily Plan</h3>
                </div>
            </div>
            <div className="card mt-3">
                <div id="accordion" className="accordion">
                    {machines?.map((machine, index) => (
                        <div key={index} className="accordion-item">
                            <a
                                href="#"
                                className="accordion-head"
                                data-bs-toggle="collapse"
                                data-bs-target={`#machine-${machine?.id}`}
                                // Review - Vaishnavi - 22/01/25 - If machine id is missing the condition should handle here
                                onClick={() =>
                                    handleAccordionClick(machine?.id)
                                }
                            >
                                <h6 className="title">
                                    {machine?.machine_name}
                                </h6>
                                <span className="accordion-icon"></span>
                            </a>
                            <div
                                className="accordion-body collapse"
                                id={`machine-${machine?.id}`}
                                data-bs-parent="#accordion"
                            >
                                <div
                                    className="accordion-inner"
                                    style={{ height: "700px" }}
                                >
                                    <DailyPlanCalendar
                                        machine_id={machine?.id}
                                        wip={machine?.wip}
                                        activeAccordion={activeAccordion}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DailyPlan;
