import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import { VARIABLES } from "../../Constant";
import secureLocalStorage from "react-secure-storage";

const EditStockTransaction = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  // select dropdown
  const [purchasedOrder, setPurchasedOrder] = useState([]);
  const [dailyPlan, setDailyPlan] = useState([]);
  const [updateType, setUpdateType] = useState([]);
  const [requisition, setRequisition] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [salesOrder, setSalesOrder] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [workOrder, setWorkOrder] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useUpdate } = useAPIQuery({
    key: "stock-transaction",
    endpoint: "/api/stock-transaction/",
  });

  const handleUpdateStockTransaction = (data) => {
    Swal.fire({
      title: "Stock Transaction Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorStockTransaction = (data) => {
    Swal.fire({
      title: "Error Updating Stock Transaction",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateStockTransaction,
    onError: handleErrorStockTransaction,
    retry : 2
  });

  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var stock_transaction = {
      quantity: data.quantity,
      purchase_order_id: data.purchase_order_id.value,
      daily_plan_id: data.daily_plan_id.value,
      update_type: data.update_type.value,
      requisition_id: data.requisition_id.value,
      transaction_type: data.transaction_type.value,
      store_approval: data.store_approval,
      QC_approval: data.QC_approval,
      department_manager_approval: data.department_manager_approval,
      so_id: data.so_id.value,
      item_id: data.item_id.value,
      wo_id: data.wo_id.value,
      is_locked: data.is_locked,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: stock_transaction,
    });
  };

  // change
  useEffect(() => {
    const editStock = location.state.editpost;
    // defaultValues is a react form hook.
    if (editStock) {
      var defaultValues = {};
      defaultValues.quantity = editStock.quantity;
      defaultValues.store_approval = editStock.store_approval;
      defaultValues.QC_approval = editStock.QC_approval;
      defaultValues.department_manager_approval =
        editStock.department_manager_approval;
      defaultValues.is_locked = editStock.is_locked;

      const getUpdateType = {
        value: editStock.update_type,
        label: editStock.update_type,
      };
      defaultValues.update_type = getUpdateType;

      const getTransactionType = {
        value: editStock.transaction_type,
        label: editStock.transaction_type,
      };
      defaultValues.transaction_type = getTransactionType;

      const getItem = {
        value: editStock.item?.id,
        label: editStock.item?.item_name,
      };
      defaultValues.item_id = getItem;

      const getSalesOrder = {
        value: editStock.so?.id,
        label: editStock.so?.so_number,
      };
      defaultValues.so_id = getSalesOrder;

      const getWorkOrder = {
        value: editStock.wo?.id,
        label: editStock.wo?.work_order_code,
      };
      defaultValues.wo_id = getWorkOrder;

      const getPurchaseOrder = {
        value: editStock.purchase_order?.id,
        label: editStock.purchase_order?.po_number,
      };
      defaultValues.purchase_order_id = getPurchaseOrder;

      const getDailyPlan = {
        value: editStock.daily_plan?.id,
        label: editStock.daily_plan?.daily_production_count,
      };
      defaultValues.daily_plan_id = getDailyPlan;

      const getRequisition = {
        value: editStock.requisition?.id,
        label: editStock.requisition?.wo?.work_order_code,
      };
      defaultValues.requisition_id = getRequisition;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setPurchasedOrder(data.purchase_order);
      setDailyPlan(data.daily_plan);
      setUpdateType(
        data.typemaster?.filter((post) => post.category_value == "update_type")
      );
      setRequisition(data.requisition);
      setTransactionType(
        data.typemaster?.filter(
          (post) => post.category_value == "transaction_type"
        )
      );
      setSalesOrder(data.sales_order);
      setItemData(data.item);
      setWorkOrder(data.work_order);
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Stock Transaction </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Sales Order<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={salesOrder?.map((api) => {
                          return { label: api.so_number, value: api.id };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-sales-order-id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="so_id"
                    control={control}
                  />
                  {errors.so_id && (
                    <span className="fs-8 text-danger">
                      Sales Order ID is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work Order<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={workOrder?.map((api) => {
                          return { value: api.id, label: api.work_order_code };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-wo_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="wo_id"
                    control={control}
                  />
                  {errors.wo_id && (
                    <span className="fs-8 text-danger">
                      Work Order ID is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={itemData?.map((api) => {
                          return { value: api.id, label: api.item_name };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Quantity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter quantity"
                    id="text-forms-quantity"
                    {...register("quantity", { required: true })}
                  />
                  {errors.quantity && (
                    <span className="fs-8 text-danger">
                      Quantity is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Transaction Type<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={transactionType?.map((api) => {
                          return { value: api.name, label: api.name };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-transaction_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="transaction_type"
                    control={control}
                  />
                  {errors.transaction_type && (
                    <span className="fs-8 text-danger">
                      Transaction Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Update Type<span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={updateType?.map((api) => {
                          return { value: api.name, label: api.name };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-update_type"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="update_type"
                    control={control}
                  />
                  {errors.update_type && (
                    <span className="fs-8 text-danger">
                      Update Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Purchased Order
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={purchasedOrder?.map((api) => {
                          return {
                            value: api.id,
                            label: api.po_number,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-purchase_order_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="purchase_order_id"
                    control={control}
                  />
                  {errors.purchase_order_id && (
                    <span className="fs-8 text-danger">
                      Purchased Order ID is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Daily Plan
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={dailyPlan?.map((api) => {
                          return {
                            value: api.id,
                            label: api.daily_production_count,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-daily_plan_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="daily_plan_id"
                    control={control}
                  />
                  {errors.daily_plan_id && (
                    <span className="fs-8 text-danger">
                      Daily Plan ID is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Requisition ID
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={requisition?.map((api) => {
                          return {
                            value: api.id,
                            label: api.wo?.work_order_code, //Change
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-requisition_id"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="requisition_id"
                    control={control}
                  />
                  {errors.requisition_id && (
                    <span className="fs-8 text-danger">
                      Requisition ID is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div class="custom-control custom-checkbox">
                    <label class="custom-control-label form-label" for="stock_approval">
                      Stock Approval
                    </label>
                    <div>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="stock_approval"
                        {...register("store_approval", { required: true })}
                      />
                    </div>
                  </div>
                  {errors.store_approval && (
                    <span className="fs-8 text-danger">
                      Stock Approval is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div class="custom-control custom-checkbox">
                    <label class="custom-control-label form-label" for="QC_approval">
                      QC Approval
                    </label>
                    <div>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="QC_approval"
                        {...register("QC_approval", { required: true })}
                      />
                    </div>
                  </div>
                  {errors.QC_approval && (
                    <span className="fs-8 text-danger">
                      QC Approval is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div class="custom-control custom-checkbox">
                    <label class="custom-control-label form-label" for="department_manager_approval">
                      Department Manager Approval
                    </label>
                    <div>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="department_manager_approval"
                        {...register("department_manager_approval", { required: true })}
                      />
                    </div>
                  </div>
                  {errors.department_manager_approval && (
                    <span className="fs-8 text-danger">
                      Department Manager Approval is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div class="custom-control custom-checkbox">
                    <label class="custom-control-label form-label" for="is_locked">
                      Is Locked
                    </label>
                    <div>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="is_locked"
                        {...register("is_locked", { required: true })}
                      />
                    </div>
                  </div>
                  {errors.is_locked && (
                    <span className="fs-8 text-danger">
                      Is Locked is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-bom-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-stock-transaction"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStockTransaction;