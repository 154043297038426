import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constant";

const EditMachine = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  // react Hook form Props
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // select dropdown
  const [item, setItem] = useState([]);
  const [department, setDepartment] = useState([]);

  const { useFetch } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data, isLoading } = useFetch({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  const { useUpdate } = useAPIQuery({
    key: "machine",
    endpoint: "/api/machine/",
  });

  const handleUpdateMachine = (data) => {
    Swal.fire({
      title: "Machine Updated!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const handleErrorMachine = (data) => {
    Swal.fire({
      title: "Error Updating Machine",
      text: data?.data?.data?.message,
      icon: "error",
    });
  };

  const { mutate, isPending } = useUpdate({
    onSuccess: handleUpdateMachine,
    onError: handleErrorMachine,
    retry : 2
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var machine = {
      item_id: data.item_id.value,
      wip: data.wip,
      department: data.department,
      capacity : data.capacity,
      machine_status_id : data.machine_status.value,
      node_id : data.node_id,
      updated_by : userID
    };

    mutate({
      id: location.state.editpost.id,
      data: machine,
    });
  };

  // Pre Population of Particular Product details
  useEffect(() => {
    const editMachine = location.state.editpost;
    // defaultValues is a react form hook.
    if (editMachine) {
      var defaultValues = {};
      defaultValues.wip = editMachine.wip;
      defaultValues.department = editMachine.department;
      defaultValues.capacity = editMachine.capacity;
      defaultValues.node_id = editMachine.node_id;

      const getItem = {
        value: editMachine.item?.id,
        label: editMachine.item?.item_name,
      };
      defaultValues.item_id = getItem;

      const getMachineStatus = {
        value: editMachine.machine_status?.id,
        label: editMachine.machine_status?.machine_status,
      };
      defaultValues.machine_status = getMachineStatus;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setItem(data.item);
      setDepartment(data.department)
    }
  }, [data]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Machine </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {/* For Form : Use React hook forms */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Item <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={item?.map((api) => {
                          return {
                            value: api.id,
                            label:
                              api.item_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-item"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="item_id"
                    control={control}
                  />
                  {errors.item_id && (
                    <span className="fs-8 text-danger">
                      Item Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Work In Progress (WIP)<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter work in progress"
                    id="text-forms-wip"
                    {...register("wip", { required: true })}
                  />
                  {errors.wip && (
                    <span className="fs-8 text-danger">
                      Work In progress is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Department <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={department?.map((api) => {
                          return {
                            value: api.id,
                            label: api.department_name,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-department"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="department"
                    control={control}
                  />
                  {errors.department && (
                    <span className="fs-8 text-danger">
                      Department name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Capacity<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter capacity of the machine"
                    id="text-forms-capacity"
                    {...register("capacity", { required: true })}
                  />
                  {errors.capacity && (
                    <span className="fs-8 text-danger">
                      Capacity of machine is required
                    </span>
                  )}
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Machine Status <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={machineStatus?.map((api) => {
                          return {
                            value: api.id,
                            label:
                              api.machine_status,
                          };
                        })}
                        isLoading={isLoading}
                        isClearable={true}
                        id="select-forms-machine_status"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="machine_status"
                    control={control}
                  />
                  {errors.machine_status && (
                    <span className="fs-8 text-danger">
                      Machine Status is required
                    </span>
                  )}
                </div> */}

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Node ID<span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter node id"
                    id="text-forms-node_id"
                    {...register("node_id", { required: true })}
                  />
                  {errors.node_id && (
                    <span className="fs-8 text-danger">
                      Node Id is required
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-md btn-light me-2"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                    {isPending ? (
                      <button
                        type="button"
                        id="button-machine-loading"
                        className="btn btn-md text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-update-machine"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMachine;