import React from "react";

const ErrorCalendar = () => {
    return (
        <div class="nk-block nk-block-middle wide-md mx-auto">
            <div class="nk-block-content nk-error-ld text-center">
                <div class="wide-xs mx-auto">
                    <h3 class="nk-error-title">Something went wrong!</h3>
                    <p class="nk-error-text">
                        We are very sorry for inconvenience. It looks like some
                        issue while loading data.
                    </p>
                    <p class="nk-error-text">Thankyou for your understanding</p>
                </div>
            </div>
        </div>
    );
};

export default ErrorCalendar;