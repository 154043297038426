import { useAPIQuery } from "@dev2_techwalnut/tw-api-query-hook";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CheckBOM from "./CheckBOM";
import { VARIABLES } from "../../Constant";
import secureLocalStorage from "react-secure-storage";

const WorkOrderSplit = () => {
  const userID = secureLocalStorage.getItem("userID");
  const delay_time = VARIABLES.delayTime;
  const navigate = useNavigate();
  const location = useLocation();
  const salesOrderId = location.state?.id;

  const [allowance, setAllowance] = useState(null);
  console.log(allowance);
  const [minimumQuantity, setMinimumQuantity] = useState(null);
  console.log(minimumQuantity);
  const [singleSplitId, setSingleSplitId] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workOrders: [
        {
          target_quantity: "",
          prod_start_date: "",
          work_order_code: "",
        },
      ],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "workOrders",
  });

  const workOrders = watch("workOrders");
  const allotedFg = watch("allotted_fg");

  const { useFetch: fetchGenericData } = useAPIQuery({
    key: "generic",
    endpoint: "/api/generic/",
  });

  const { data: genericData, isLoading: IsGenericLoading } = fetchGenericData({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    refetchInterval: delay_time, // after every 2 seconds the generic api recalls
  });

  if (!salesOrderId) navigate("/not-found");

  const { useFetch: fetchSalesOrder, useUpdate: updateSalesOrder } =
    useAPIQuery({
      key: `sales_order_for_${salesOrderId}`,
      endpoint: `/api/sales-order/${salesOrderId}/`,
    });

  const {
    data: salesOrderData,
    isLoading: isSalesOrderLoading,
    isError: isSalesOrderError,
    refetch: refetchSalesOrder,
  } = fetchSalesOrder({
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
  });

  const salesOrder = salesOrderData?.results?.data;
  const stockQuantity = salesOrder?.product?.stock_quantity;
  const targetQuantity = salesOrder?.to_be_produced;

  const totalProduced =
    workOrders?.length > 0
      ? workOrders.reduce(
          (total, work) => total + (Number(work.target_quantity) || 0),
          0
        )
      : 0;

  const { useFetch: fetchWorkOrder, useCreate: createWorkOrder } = useAPIQuery({
    key: "work_order",
    endpoint: "/api/work-order/",
  });

  const { useDelete: deleteWorkOrder } = useAPIQuery({
    key: "work_order",
    endpoint: "/api/work-order/",
  });

  const {
    data: workOrderData,
    isLoading: isWorkOrderLoading,
    refetch: refetchWorkOrder,
  } = fetchWorkOrder({
    params: {
      sales_order_id: salesOrder?.id,
    },
    abortOnNewRequest: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
    retry: 3,
    keepPlaceholder: true,
    refetchOnReconnect: true,
    enabled: !!salesOrder,
  });

  const handleCreateWorkOrder = (data) => {
    Swal.fire({
      title: "Work Order Added!",
      text: data.message,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        refetchWorkOrder();
        setTimeout(() => {}, 2000);
      }
    });
  };

  const handleErrorWorkOrder = (data) => {
    Swal.fire({
      title: "Error Adding Work Order",
      text: data?.data?.data?.message,
      icon: "error",
    }).then((result) => {
      if (result.isConfirmed) {
        refetchWorkOrder();
        setTimeout(() => {}, 2000);
      }
    });
  };

  const handleMutateSalesOrder = () => {
    console.log("refetching work order");
    refetchWorkOrder();
    refetchSalesOrder();
  };

  const { mutate: mutateSalesOrder } = updateSalesOrder({
    onSuccess: handleMutateSalesOrder,
  });

  const { mutate: mutateCreateWorkOrder, isPending } = createWorkOrder({
    onSuccess: handleCreateWorkOrder,
    onError: handleErrorWorkOrder,
  });

  const { mutate: deleteWorkOrderData } = deleteWorkOrder();

  const handleDeleteRow = (row) => {
    console.log(row);
    const entry_id = row;
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWorkOrderData({ id: entry_id });
      }
    });
  };

  const validateAllottedFG = (value) => {
    if (value > stockQuantity) {
      return `stock limit: ${stockQuantity}`;
    }
    return true;
  };

  const validateTotalTargetQuantity = (value, index) => {
    console.log(value);
    const totalTargetQuantity = workOrders.reduce(
      (sum, item, i) =>
        sum + (i === index ? value : Number(item.target_quantity)),
      0
    );

    // Calculate the allowed limit (5% more than allottedQuantity)
    // allowance fetch from type master.
    const allowedLimit = targetQuantity * allowance?.value; // 105% of allottedQuantity

    // Check if the totalTargetQuantity exceeds the allowed limit
    const isValid = totalTargetQuantity <= allowedLimit;
    if (!isValid) {
      return `Total target quantity exceeds the allowed 5% tolerance (${allowedLimit.toFixed(
        2
      )}).`;
    }
    return isValid; // Return true or false for validation
  };

  const validateMinWOQuentity = (value) => {
    // MinimumQuantity fetch from typemaster
    const allowedLimit = targetQuantity * minimumQuantity?.value;
    if (allowedLimit > value) {
      return `Minimum work order quantity is ${allowedLimit}`;
    }
    return true;
  };

  const onFGoodsHandler = () => {
    if (allotedFg === null || allotedFg === undefined) return;
    if (allotedFg <= stockQuantity && allotedFg >= 0) {
      Swal.fire({
        text: `Would you like to allott ${allotedFg} Finished Good?`,
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No, cancel!`,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("error");
          mutateSalesOrder({
            data: {
              stock_allotted: Number(allotedFg),
              is_stock_allotted: true,
              updated_by: userID,
            },
          });
        }
      });
    } else if (allotedFg < 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: `Stock Allotted cannot be negative`,
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: `Stock Allotted cannot exceed the allotted stock of ${stockQuantity}`,
        confirmButtonText: "OK",
      });
    }
  };

  const handleSplit = () => {
    // REVIEW: Why need to add 1 to the fields length?
    // Ans : If the field.length = 4 and user hit the global splitworkorder button then 4 + 1 = 5 it will split into 5 rows
    const numFields = fields.length + 1;
    const splitValue = Math.floor(totalProduced / numFields);
    const remainder = totalProduced % numFields;

    fields.forEach((_, index) => {
      setValue(`workOrders.${index}.target_quantity`, splitValue);
    });

    append({
      target_quantity: splitValue + remainder,
      prod_start_date: workOrderData?.results?.data[0]?.prod_start_date,
    });

    var bulk_work_order = workOrders?.map((api) => {
      return {
        id: api.workOrderId || null,
        sales_order_id: salesOrder?.id,
        prod_start_date: api.prod_start_date,
        dispatch_date: api.dispatch_date,
        target_quantity: api.target_quantity,
        activity_status: api.activityStatus,
      };
    });

    console.log([
      ...bulk_work_order,
      {
        id: null,
        target_quantity: splitValue + remainder,
        prod_start_date: workOrderData?.results?.data[0]?.prod_start_date,
      },
    ]);

    mutateCreateWorkOrder({
      data: {
        sales_order_id: salesOrder?.id,
        created_by : userID,
        updated_by : userID,
        // total_target_quantity: totalProduced,
        bulk_upload: [
          ...bulk_work_order,
          {
            id: null,
            target_quantity: splitValue + remainder,
            prod_start_date: workOrderData?.results?.data[0]?.prod_start_date,
            activity_status: 2,
            // sales_order_id: salesOrder?.id,
          },
        ],
      },
    });
  };

  const handleSplitIndex = (index) => {
    const currentEntry = fields[index];
    const firstHalf = Math.floor(currentEntry.target_quantity / 2);
    const secondHalf = currentEntry.target_quantity - firstHalf;

    if (firstHalf > 0) {
      setValue(`workOrders.${index}.target_quantity`, firstHalf);
      append({
        target_quantity: secondHalf,
        prod_start_date: workOrderData?.results?.data[0]?.prod_start_date,
        // dispatch_date : ""
      });

      var bulk_work_order = workOrders?.map((api) => {
        return {
          id: api.workOrderId || null,
          // sales_order_id: salesOrder.id,
          prod_start_date: api.prod_start_date,
          dispatch_date: api.dispatch_date,
          target_quantity: api.target_quantity,
          activity_status: api.activityStatus,
        };
      });

      console.log([
        ...bulk_work_order,
        {
          id: null,
          target_quantity: secondHalf,
          prod_start_date: workOrderData?.results?.data[0]?.prod_start_date,
        },
      ]);

      mutateCreateWorkOrder({
        data: {
          sales_order_id: salesOrder?.id,
          created_by : userID,
          updated_by : userID,
          // total_target_quantity: totalProduced,
          bulk_upload: [
            ...bulk_work_order,
            {
              id: null,
              target_quantity: secondHalf,
              prod_start_date: workOrderData?.results?.data[0]?.prod_start_date,
              dispatch_date: currentEntry.dispatch_date,
              // sales_order_id: salesOrder?.id,
              activity_status: 2,
            },
          ],
        },
      });
    }
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  // TO DO : Pre populate of work order
  // REVIEW: Purpose for use memo?
  // REVIEW: How is created_by and updated_by passed to the api?
  useEffect(() => {
    if (stockQuantity === undefined || stockQuantity === null) return;
    let work_order_data = workOrderData?.results?.data;
    if (work_order_data) {
      console.log(work_order_data);
      let defaultValues = {};
      defaultValues.workOrders = work_order_data.map((wo) => ({
        ...wo,
        workOrderId: wo.id,
        activityStatus: wo.activity_status,
        id: wo.id,
      }));
      defaultValues.allotted_fg = stockQuantity;
      reset(defaultValues);
    }
  }, [stockQuantity, workOrderData]);
  // useMemo(() => {
  //   if (stockQuantity === undefined || stockQuantity === null) return;
  //   console.log(workOrderData);
  //   let defaultValues = {};
  //   defaultValues.workOrders = workOrderData?.results?.data.map((wo) => ({
  //     ...wo,
  //     workOrderId: wo.id,
  //     activityStatus: wo.activity_status,
  //     id: wo.id,
  //   }));
  //   defaultValues.allotted_fg = stockQuantity;
  //   reset(defaultValues);
  // }, [stockQuantity, workOrderData]);

  useEffect(() => {
    if (genericData) {
      setAllowance(
        genericData.typemaster?.find(
          (post) => post.category_value == "allowance_tolerance"
        )
      );
      setMinimumQuantity(
        genericData.typemaster?.find(
          (post) => post.category_value == "minimum_target_quantity_wo"
        )
      );
    }
  }, [genericData]);

  // REVIEW: Why the plain divs defined here?
  if (isSalesOrderLoading) return <div>Loading...</div>;
  if (isSalesOrderError) return <div>Error</div>;
  if (!salesOrder) return <div>No data found</div>;

  return (
    <div className="card">
      <div className="card-inner">
        <div className="nk-block">
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Item Name</span>
                <span className="profile-ud-value">
                  {salesOrder?.product?.item_name}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Client Name</span>
                <span className="profile-ud-value">
                  {salesOrder?.client?.user?.first_name +
                    " " +
                    salesOrder?.client?.user?.last_name}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Sales order</span>
                <span className="profile-ud-value">
                  {salesOrder?.so_number}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Product Code</span>
                <span className="profile-ud-value">
                  {salesOrder?.product?.item_code}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Total Quantity</span>
                <span className="profile-ud-value">
                  {salesOrder?.to_be_produced}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">BOM Code</span>
                {/* REVIEW: BOM code should not be hardcoded */}
                <span className="profile-ud-value">BO-001</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Location</span>
                <span className="profile-ud-value">
                  {salesOrder?.dispatch_location}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Target Date</span>
                <span className="profile-ud-value">
                  {salesOrder?.target_date}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                {salesOrder?.is_stock_allotted ? (
                  <div className="d-flex">
                    <span className="profile-ud-label">Allotted FG</span>
                    <div>{salesOrder?.stock_allotted}</div>
                  </div>
                ) : (
                  <div className="row d-flex align-items-center">
                    <span className="profile-ud-label">Allott FG</span>
                    <div className="col-6">
                      <input
                        type="number"
                        className="form-control"
                        id="text-forms-fg"
                        {...register("allotted_fg", {
                          required: "This field is required",
                          validate: validateAllottedFG,
                        })}
                        // REVIEW: Can we fetch the activity status comparison value globally.
                        // TODO: Not to be implemented yet.
                        disabled={salesOrder?.activity_status !== 1}
                      />
                      {errors.allotted_fg && (
                        <p style={{ color: "red" }}>
                          {errors.allotted_fg.message}
                        </p>
                      )}
                    </div>
                    <div className="col-2 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary"
                        onClick={onFGoodsHandler}
                        disabled={salesOrder?.activity_status !== 1}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {salesOrder?.activity_status === 1 ? (
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <button
                    type="button"
                    onClick={() => handleSplit()}
                    className="btn btn-outline-primary"
                    disabled={!salesOrder?.is_stock_allotted || isPending}
                  >
                    <em className="icon ni ni-plus"></em>
                    <span>Split Work Order</span>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {salesOrder?.activity_status === 1 ? (
          <>
            <div className="nk-block">
              <div className="profile-ud-list">
                <div className="profile-ud-item2">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label fw-bold text-dark">
                      Target Quantity
                    </span>
                    <span className="profile-ud-value fs-6">
                      {targetQuantity}
                    </span>
                  </div>
                </div>
                <div className="profile-ud-item2">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label fw-bold text-dark">
                      To be Produced
                    </span>
                    <span className="profile-ud-value fs-6">
                      {totalProduced}
                    </span>
                    {errors?.workOrders && (
                      <p
                        style={{
                          color: "red",
                        }}
                      >
                        {errors?.workOrders?.[errors?.workOrders.length - 1]
                          ?.target_quantity?.type ===
                          "validateTargetQuentity" &&
                          errors?.workOrders?.[errors?.workOrders.length - 1]
                            ?.target_quantity?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="profile-ud-item2">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label fw-bold text-danger">
                      Pending Target
                    </span>
                    <span className="profile-ud-value fs-6 text-danger">
                      {Math.max(targetQuantity - totalProduced, 0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* If it's the first split, show only one button */}
              {
                // isSplit &&
                // Show the dynamic list after the first split
                fields.map((field, index) => (
                  <div className="card my-2" key={field.id}>
                    <div className="card-inner">
                      <div className="row g-4">
                        <div
                          className="form-group"
                          style={{
                            display: "none",
                          }}
                        >
                          <input
                            type="hidden"
                            {...register(`workOrders.${index}.activityStatus`)}
                          />
                          <input
                            type="hidden"
                            {...register(`workOrders.${index}.workOrderId`)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                          <label className="form-label">Target Quantity</label>
                          <input
                            className="form-control"
                            type="number"
                            style={{
                              border:
                                errors?.workOrders?.[index]?.target_quantity
                                  ?.type === "validateMinWOQuentity"
                                  ? "1px solid red"
                                  : errors?.workOrders?.[
                                      errors?.workOrders.length - 1
                                    ]?.target_quantity?.type ===
                                    "validateTargetQuentity"
                                  ? "1px solid red"
                                  : "",
                            }}
                            {...register(
                              `workOrders.${index}.target_quantity`,
                              {
                                valueAsNumber: true,
                                validate: {
                                  validateTargetQuentity: (value) =>
                                    validateTotalTargetQuantity(value, index),
                                  validateMinWOQuentity: validateMinWOQuentity,
                                },
                              }
                            )}
                            disabled={!salesOrder?.is_stock_allotted}
                          />
                          <p className="text-danger mb-0">
                            {/* TO DO : for individual target quantity */}
                            Percentage :
                            {/* The individual target quantity is the percentage of individual target quantity by to be produced quantity(Calculated by summation of all the workorder quantity). Not the target quantity  */}
                            {workOrders[index]?.target_quantity
                              ? (
                                  (parseFloat(
                                    workOrders[index].target_quantity
                                  ) /
                                    targetQuantity) *
                                  // REVIEW: Percentage should always be multiplied by 100
                                  100
                                ).toFixed(2)
                              : "0.00"}
                            %
                          </p>
                          <span className="text-danger">
                            {errors?.workOrders?.[errors?.workOrders.length - 1]
                              ?.target_quantity?.type ===
                              "validateMinWOQuentity" &&
                              errors?.workOrders?.[index]?.target_quantity
                                ?.message}
                          </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                          <label className="form-label">
                            Production Start Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            {...register(`workOrders.${index}.prod_start_date`)}
                            disabled={!salesOrder?.is_stock_allotted}
                          />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                          <label className="form-label">Dispatch Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register(`workOrders.${index}.dispatch_date`)}
                            disabled={!salesOrder?.is_stock_allotted}
                          />
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-12 d-flex align-items-center justify-content-start">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            onClick={() => {
                              handleSplitIndex(index);
                              setSingleSplitId(field.workOrderId);
                            }}
                            disabled={
                              !salesOrder?.is_stock_allotted || isPending
                            }
                          >
                            Split Workorder
                          </button>
                        </div>
                        {index !== 0 && (
                          <div className="col-lg-1 col-md-1 col-sm-12 d-flex align-items-center">
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-danger"
                              onClick={() => handleDeleteRow(field.workOrderId)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-head-content">
                              <div className="d-flex align-items-baseline">
                                <h6 className="h7 pe-3">
                                  {field.work_order_code}
                                </h6>
                                <CheckBOM
                                  item_id={salesOrder?.product?.id}
                                  wo_id={field.workOrderId}
                                  salesorder_id={salesOrderId}
                                  disabled={
                                    !salesOrder?.is_stock_allotted || isPending
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </form>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default WorkOrderSplit;
